import axios from "../../config/axios"
import ApiRoute from '../api.route';
import { base_url_general } from "../../config/base_url"

export async function getUserById(id) {
    return axios({
        method: 'GET',
        url: ApiRoute.user + '/' + id,
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function getAccountInfo() {
    return axios({
        method: 'GET',
        url: ApiRoute.account,
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function updateUser(formData, id) {
    return axios.put(ApiRoute.user + '/' + id, formData)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
      })
}

export async function updateProfile(formData) {
    return axios.put(ApiRoute.editProfile, formData)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
      })
}

export async function getCardPaymentMethod() {
    return axios({
        method: 'GET',
        url: base_url_general + ApiRoute.paymentMethod
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data != null ? res.data.data : [],
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}
export async function createCardPaymentMethod(payment) {
    return axios.post( base_url_general + ApiRoute.paymentMethod, payment).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}
export async function deleteCardPaymentMethod(id) {
    return axios({
        method: 'DELETE',
        url: base_url_general + ApiRoute.paymentMethod+"/"+id,
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function updateStatusUsedCard(idCard) {
    return axios.patch( base_url_general + ApiRoute.paymentMethod+"/"+idCard).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}
export async function paymentHistory(page, limit) {
    return axios.get( base_url_general + ApiRoute.paymentHistory+"?page="+page+"&limit="+limit).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
            totalData: res.data.totalData,
            totalPage: res.data.totalPage,
            page: res.data.page,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function updateCardPaymentMethod(payment) {
    return axios.put( base_url_general + ApiRoute.paymentMethod+"/"+payment._id, payment).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function getUser() {
    return axios({
        method: 'GET',
        url: base_url_general + 'api/user'
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function checkTakeTourVideo() {
    return axios({
        method: 'GET',
        url: ApiRoute.user + '/check/take-tour-video',
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}

export async function updateTakeTourVideo() {
    return axios({
        method: 'PATCH',
        url: ApiRoute.user + '/finish/take-tour-video',
    }).then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}