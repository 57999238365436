import axios from "../../config/axios"
import ApiRoute from '../api.route';

import { base_url_landing_page } from "../../config/base_url";

export async function checkCommunity() {
    return axios.get(base_url_landing_page + ApiRoute.community + '/check')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function createCommunity(formData) {
	return axios.post(base_url_landing_page + ApiRoute.community + '/create', formData)
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getOwnershipCommunity() {
    return axios.get(base_url_landing_page + ApiRoute.community + '/list-owner')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getMemberCommunity() {
    return axios.get(base_url_landing_page + ApiRoute.community + '/list-member')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function addMemberCommunity(formData) {
	return axios.post(base_url_landing_page + ApiRoute.community + '/add-member', formData)
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function kickMemberCommunity(formData) {
	return axios.put(base_url_landing_page + ApiRoute.community + '/kick-member', formData)
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function joinCommunity(formData) {
	return axios.post(base_url_landing_page + ApiRoute.community + '/join', formData)
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getListInvitationCommunity() {
    return axios.get(base_url_landing_page + ApiRoute.community + '/invitations')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function putCommunity(formData) {
	return axios.put(base_url_landing_page + 'api/allocations/change', formData)
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function switchCommunity() {
	return axios.put(base_url_landing_page + 'api/allocations/switch')
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getDetailCommunity(id) {
    return axios.get(base_url_landing_page + ApiRoute.community + '/detail/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function setLimitCommunity(id, formData) {
    return axios.patch(base_url_landing_page + ApiRoute.community + '/set-limit/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getListMemberDowngradeCommunity(id) {
    return axios.get(base_url_landing_page + ApiRoute.community + '/list-select-member/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function selectMemberDowngradeCommunity(formData) {
	return axios.post(base_url_landing_page + ApiRoute.community + '/select-member', formData)
	.then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}