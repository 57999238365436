
import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getCategories() {
  return axios({
    method: 'GET',
    url: ApiRoute.getCategories
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
      page: res.data.page,
      limit: res.data.limit,
      totalPage: res.data.totalPage,
      totalData: res.data.totalData,
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getCategoriesDetail(id, params) {
  return axios.get(ApiRoute.getCategories + '/detail/' + id,{ params: params})
  .then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
      page: res.data.page,
      limit: res.data.limit,
      totalPage: res.data.totalPage,
      totalData: res.data.totalData,
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getCategoriesAll() {
  return axios({
    method: 'GET',
    url: ApiRoute.getCategories + '/all'
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}



export async function getCategoriesById(id) {
  return axios({
    method: 'GET',
    url: ApiRoute.getCategories + '/' + id,
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
    }
    return data;
  }).catch((error) => {
    const data = {
      status: false,
      response: error
    }
    return data;
  })
}

export async function addCategories(formData) {
  return axios.post(ApiRoute.getCategories, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function updateCategories(formData, id) {
  return axios.put(ApiRoute.getCategories + '/' + id, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function deleteCategories(id) {
  return axios.delete(ApiRoute.getCategories + '/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function getCategoriesTtq() {
  return axios({
    method: 'GET',
    url: ApiRoute.categoriesTtq
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}
