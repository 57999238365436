import axios from "../../config/axios";
import ApiRoute from '../api.route';
import { base_url_general } from "../../config/base_url"

export async function getProjects(){
    return axios({
        method : 'GET',
        url : ApiRoute.getProjects,
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message, 
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }   
        return data;
      })
}

export async function getProjectIdByEmail(email) {
  return axios({
      method: 'GET',
      url: base_url_general + ApiRoute.getProjectIdByEmail +'/' + email
  })
  .then((res) => {
      const data = {
          status: res.data.status,
          data: res.data.data,
          message: res.data.message
      }
      return data;
  })
  .catch((error) => {
      const data = {
          status: false,
          response: error
      }
      return data;
  })
}

// auth 
export async function registerUser(data_post) {
  
  var axiosInstance = axios.create({
      headers: ""
  })
  return axiosInstance.post(base_url_general + ApiRoute.userGeneralData, data_post)
  .then(res => {
      const data = res.data
      return data;
  })
  .catch((error) => {
      const data = {status : false , response : error }
      return data;
  })
}

export async function updateDataUser(email, data_post) {
    var axiosInstance = axios.create({
        headers: ""
    })
    return axiosInstance.put(base_url_general + ApiRoute.userGeneralData + '/' + email, data_post)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function getTokenByEmail(email) {

    var axiosInstance = axios.create({
        baseURL : base_url_general,
        headers: ""
    })

  return axiosInstance.get(base_url_general + ApiRoute.userGeneralData + '/' + email)
  .then((res) => {
      const data = {
          status: res.data.status,
          data: res.data.data,
          message: res.data.message
      }
      return data;
  })
  .catch((error) => {
      const data = {
          status: false,
          response: error
      }
      return data;
  })
}

//end service auth