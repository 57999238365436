<template>
<div>
    <nav class="navbar bg-black">
        <a class="navbar-brand mx-4" href="#"><img src="@/assets/landing-page/soca-text-putih.svg" alt=""></a>
    </nav>
    <div v-if="viewportWidth > 860" class="ini-body text-center mx-4 ">
        <h1 class="">Saya menggunakan Soca untuk</h1>
        <div class="container-md w-50 d-flex justify-content-center">
            <div>
                <div class="card btn" @click="selectType(1)" style="width : 38rem">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="@/assets/landing-page/img-role.svg" alt="" class="img-role d-flex mx-3">
                            </div>
                            <div class="col-md-9 pe-0">
                                <div class="d-flex justify-content-start">
                                    <h3 class=""><b>Belajar</b></h3>
                                </div>
                                <p class="info text-start mb-0">Menemukan materi yang suka dan mempelajari hal yang baru.</p>
                            </div>
                            <div class="col-md-1">
                                <p><img src="@/assets/landing-page/fwd-right.svg" alt="" srcset=""></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card btn mt-3" @click="selectType(2)" style="width : 38rem">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="@/assets/landing-page/img-role-2.svg" alt="" class="img-role d-flex mx-3">
                            </div>
                            <div class="col-md-9 pe-0">
                                <div class="d-flex justify-content-start">
                                    <h3 class=""><b>Berkreasi</b></h3>
                                </div>
                                <p class="info text-start mb-0">Membuat materi, konten belajar, desain karakter dan dunia 3D.</p>
                            </div>
                            <div class="col-md-1 pe-0">
                                <p><img src="@/assets/landing-page/fwd-right.svg" alt="" srcset=""></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="contaiener-fluid">
        <div class="px-3 d-flex flex-column justify-content-center align-items-center container-choose-role">
            <h3 class="fw-bold">
                Saya menggunakan Soca untuk
            </h3>
            <div class="my-3 px-3 py-3 card w-100" @click="selectType(1)">
                <div class="d-flex justify-content-around align-items-start">
                    <img src="@/assets/landing-page/learner.svg" />
                    <div class="ms-3">
                        <h5 class="m-0 fw-bold">Belajar</h5>
                        <p class="description">Menemukan meteri yang suka dan mempelajari hal yang baru</p>
                    </div>
                    <button class="button-next">
                        <img src="@/assets/landing-page/chevronBlack.svg" />
                    </button>
                </div>
            </div>
            <div class="my-3 px-3 py-3 card w-100" @click="selectType(2)">
                <div class="d-flex justify-content-around align-items-start">
                    <img src="@/assets/landing-page/creator.svg" />
                    <div class="ms-3">
                        <h5 class="m-0 fw-bold">Berkreasi</h5>
                        <p class="description">Membuat materi, konten belajar, desain karakter dan dunia 3D</p>
                    </div>
                    <button class="button-next">
                        <img src="@/assets/landing-page/chevronBlack.svg" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return {
            viewportWidth: window.innerWidth,
        }
    },
    methods:{
        selectType(role){
            this.$router.push({ name: 'ChooseType', query: {role : role, go : this.$route.query.go} })
        }
    }
}
</script>

<style scoped>
.btn {
    width: 670px;
    height: 140px;
    background-color: white;
    color: black;
}
.info {
    font-weight: 200;
    font-size: 19px;
    color: #636363;
}
.ini-body h1 {
    margin-bottom: 3rem;
    font-weight: 530;
    margin-top: 5rem;
    font-size: 33px;
}
.card {
    border-radius: 20px;
}

.bg-black{
    background-color: black;
}

@media only screen and (max-width: 860px) {
    .navbar-brand img {
        width: 70px;
    }

    .container-choose-role {
        min-height: 100vh;
        height: 100%;
    }

    .card {
        background-color: transparent;
        border: 1px solid #D9D9D9;
        border-radius: 16px;
    }

    .button-next {
        border: none;
        background-color: transparent;
    }

    .description {
        color: #636363;
    }
}
</style>