import axios from "../../config/axios"
import ApiRoute from '../api.route';


export async function postQuestionCreator(formData, id) {
    return axios.post(ApiRoute.postQuestionCreator + '/' + id, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getQuestionCreator(id) {
    return axios.get(ApiRoute.getQuestionCreator + '/' + id)
        .then(res => {
            const data = {
                status: res.data.status,
                data: res.data.data,
                message: res.data.message,
            }
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function addQuestionCreator(formData, id) {
    return axios.post(ApiRoute.getQuestionCreator + '/' + id, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function putQuestionCreator(formData, id) {
    return axios.put(ApiRoute.getQuestionCreator + '/' + id, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function deleteQuestionCreator(id) {
    return axios.delete(ApiRoute.getQuestionCreator + '/' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function uploadQuestionCreator(formData, config){
    return axios.post(ApiRoute.uploadQuestionCreator, formData, config)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}