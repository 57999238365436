import Vue from 'vue'
import Vuex from 'vuex'
import { updateQuizCreator, postContent } from '@/services/quiz-creator/quiz-creator.service'
import { addQuestionCreator } from '@/services/question-creator/question_creator.service'
import { putQuestionCreator } from '@/services/question-creator/question_creator.service'
import { addQuizCreator } from '@/services/quiz-creator/quiz-creator.service'
import { postQuestionCreator } from '@/services/question-creator/question_creator.service'
import { deleteQuestionCreator } from '@/services/question-creator/question_creator.service'
import { addPolling } from '@/services/pollings/pollings.service'
import { addQuestionPolling } from '@/services/question-polling/quesiton_polling.service'
import { getSearchEngine } from "@/services/general/general.service";

// Get Modules
import users from './modules/users'
import AuthorQuiz from './modules/AuthorQuiz'


import router from '@/router/index'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        users: {},
        isSidebarActive: true,
        isSidebarActiveByHamburger: false,
        themeColor: '#2962ff',
        validationTemplate: false,
        validationCreate: false,
        searchStat: false,
        loadingSe: false,
        loadingAllContents: false,
        filterSearchEngine: null,
        quizAll: [],
        dataTop: [],
        dataCreator: [],
        dataCollections: [],
        dataQuizCollections: [],
        dataAudioCollections: [],
        dataTag: [
            {
                category_id: null,
                category_name: null,
                _id: null,
                data_quiz_all: [],
            },
        ],
        //update-question from template
        templateData: {
            question_type: 'Multiple Choice',
            image_url: '',
            quiz_id: '',
            question: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            point: 10,
            duration: 30,
            // Untuk matching type
            match_1: '',
            match_2: '',
            match_3: '',
            match_4: '',
            img_option_1: '',
            img_option_2: '',
            img_option_3: '',
            img_option_4: '',
            point_match_1: '10',
            point_match_2: '10',
            point_match_3: '10',
            point_match_4: '10',
            correct_answer: '',
            index: 1
        },

        templateQuiz: {
            quiz_name: '',
            is_use_template: true,
            category_id: '',
            quiz_template_id: null,
            image_cover: '',
            description: null,
        },
        listTemplateQuestion: [], //listQuestion
        newListTemplateQuestion: [], //newListQuestion
        templateCurrentSoal: 1, //currentSoal
        opsi1Template: null,
        opsi2Template: null,
        opsi3Template: null,
        opsi4Template: null,
        formQuizCreator: {
            quiz_name: '',
            is_use_template: true,
            category_id: '',
            quiz_template_id: null,
            description: null,
            image_cover: '',
            grade_id: null,
            sub_grade_id: null,
        },

        // edit-my-quiz
        newQuestion: [],
        oldQuestion: [],
        listQuestion: [],
        realQuestion: [],
        deletedDataEdit: [],
        dataQuiz: {
            category_id: null,
            learning_category_id: null,
            grade_id: null,
            quiz_name: null,
            description: null,
            author: null,
            images_cover: null,
            categories_quiz: {
                category_name: null
            }
        },

        // Create quiz creator
        createData: {
            question_type: 'Multiple Choice',
            image_url: '',
            quiz_id: '',
            question: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            voice: '',
            video: '',
            point: 10,
            duration: 30,
            // Untuk matching type
            match_1: '',
            match_2: '',
            match_3: '',
            match_4: '',
            img_option_1: '',
            img_option_2: '',
            img_option_3: '',
            img_option_4: '',
            point_match_1: '10',
            point_match_2: '10',
            point_match_3: '10',
            point_match_4: '10',
            correct_answer: '',
            index: 1
        },
        listCreateQuestion: [], //listQuestion
        newListCreateQuestion: [], //newListQuestion
        createCurrentSoal: 1, //currentSoal
        opsi1: null,
        opsi2: null,
        opsi3: null,
        opsi4: null,

        // Update quiz creator
        updateData: {
            question_type: 'Multiple Choice',
            image_url: '',
            quiz_id: '',
            question: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            voice: '',
            video: '',
            point: 10,
            duration: 30,
            // Untuk matching type
            match_1: '',
            match_2: '',
            match_3: '',
            match_4: '',
            img_option_1: '',
            img_option_2: '',
            img_option_3: '',
            img_option_4: '',
            point_match_1: '10',
            point_match_2: '10',
            point_match_3: '10',
            point_match_4: '10',
            correct_answer: '',
            index: 1
        },
        listUpdateQuestion: [], //listQuestion
        newListUpdateQuestion: [], //newListQuestion
        updateCurrentSoal: 1, //currentSoal
        opsi1Update: null,
        opsi2Update: null,
        opsi3Update: null,
        opsi4Update: null,

        canceledTempalte: false,

        canceledCreate: false,

        canceledUpdate: false,

        question_null: true,

        loadingUpdate: false,

        loadingCreate: false,

        isTtqGenerated: false,

        check_length: 0,

        // Create polling creator
        createPollingData: {
            question_type: 'Multiple Choice',
            image_url: '',
            quiz_id: '',
            question: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            correct_answer: '',
            index: 1
        },
        listCreatePollingQuestion: [], //listQuestion
        newListCreatePollingQuestion: [], //newListQuestion
        createPollingCurrentSoal: 1, //currentSoal
        opsi1PollingCreate: null,
        opsi2PollingCreate: null,
        opsi3PollingCreate: null,
        opsi4PollingCreate: null,

        canceledCreatePolling: false,


        // RESPONSE
        /* EDIT TEMPLATE */
        responseSoal: "Max Characters 120",
        responseAnswer: "Max Characters 70",

        showAlertSoalTemp: false,

        showAlertAnswer1Temp: false,

        showAlertAnswer2Temp: false,

        showAlertAnswer3Temp: false,

        showAlertAnswer4Temp: false,

        /* CREATE QUESTION */
        showAlertSoalCreate: false,

        showAlertAnswer1Create: false,

        showAlertAnswer2Create: false,

        showAlertAnswer3Create: false,

        showAlertAnswer4Create: false,

        /* UPDATE QUESTION */
        showAlertSoalUpdate: false,

        showAlertAnswer1Update: false,

        showAlertAnswer2Update: false,

        showAlertAnswer3Update: false,

        showAlertAnswer4Update: false,

        // POLLING
        /* CREATE QUESTION */
        showAlertSoalCreatePolling: false,

        showAlertAnswer1CreatePolling: false,

        showAlertAnswer2CreatePolling: false,

        showAlertAnswer3CreatePolling: false,

        showAlertAnswer4CreatePolling: false,

        /* CHANGE ON NAVBAR (for generate text to audio) */
        generatedAudioContent: false,

        generatedQuiz: false,

    },
    mutations: {
        IS_SIDEBAR_ACTIVE(state, value) {
            state.isSidebarActive = value;
        },

        IS_SIDEBAR_ACTIVE_BY_HAMBURGER(state, value) {
            state.isSidebarActiveByHamburger = value;
        },

        publishCreateQuestion(state, payload) {
            state.loadingCreate = true;
            let kategori = localStorage.category_id
            // let vm = this
            let formData = {
                quiz_name: localStorage.quiz_name,
                description: localStorage.quiz_description,
                images_cover: localStorage.images_cover,
                category_id: kategori == '1' || kategori == '2' || kategori == '3' || kategori == '4' || kategori == '5' ? '64215d46fb6ea4ab650b1307' : kategori ,
                grade_id: localStorage.grade ? localStorage.grade : localStorage.levelAi ? localStorage.levelAi : null,
                sub_grade_id: localStorage.sub_grade,
                is_use_template: payload.author == 0 ? true : false,
                quiz_template_id: payload.id != null ? payload.id : null,
                is_draft: false,
                topic: kategori == '1' || kategori == '2' || kategori == '3' || kategori == '4' || kategori == '5' ? kategori : null
            }

            let listQuestion = JSON.parse(localStorage.listQuestionCreate)
            addQuizCreator(formData)
                .then((response) => {
                    if (response.status && response.status == true) {
                        listQuestion.forEach((item) => {
                            console.log(item)
                            item._id = null; //re-create id for new question
                            if (item.voice != null && item.voice != '') {
                                item.question = null
                            }

                            if (item.video != null && item.video != '') {
                                item.question = null
                            }
                            postQuestionCreator(item, response.data._id)
                                .then((response) => {
                                    if (response.status && response.status == true) {
                                        state.validationCreate = false
                                    } else {
                                        console.log(response)
                                    }
                                })
                        })
                        state.loadingCreate = false;
                        var id_quiz = response.data._id

                        const length = listQuestion.reduce((totalDuration, question) => {
                            return totalDuration + (question.duration || 30);
                        }, 0);

                        if (localStorage.getItem("quizTime")) {
                            localStorage.removeItem("quizTime")
                        }

                        if (localStorage.getItem("sisaWaktu")) {
                            localStorage.removeItem("sisaWaktu")
                        }
                        localStorage.setItem("quizTime", length)
                        localStorage.removeItem("listQuestionCreate")
                        localStorage.removeItem("quiz_name")
                        localStorage.removeItem("quiz_description")
                        localStorage.removeItem("images_cover")
                        localStorage.removeItem("category_id")
                        localStorage.removeItem("createTag")
                        localStorage.removeItem("materiAi")
                        localStorage.removeItem("gradeAi")
                        localStorage.removeItem('lengthQuestionAi')
                        localStorage.removeItem("subGradeAi")
                        localStorage.removeItem("descriptionAi")
                        localStorage.removeItem("grade")
                        localStorage.removeItem("sub_grade")
                        localStorage.removeItem("index_grade")
                        localStorage.removeItem("backUpCreateQuestion")

                        router.push({ name: 'Use-Template', query: { key: id_quiz, creator: true } })
                    } else {
                        console.log(response)
                        state.loadingCreate = false;
                        state.validationCreate = true;
                    }
                })
        },

        // edit-my-quiz method
        publishQuiz(state, value) {
            state.loadingUpdate = true;
            if (value != undefined) {
                let dataQuestionNew = localStorage.getItem("newListQuestionCreator");
                let oldDataQuestion = localStorage.getItem("listQuestionCreator");
                let dataDeleted = localStorage.getItem("deletedEditQuestion")
                let kategori = localStorage.category_id_edit

                if (dataQuestionNew) {
                    state.newQuestion = JSON.parse(dataQuestionNew);
                }

                if (oldDataQuestion) {
                    state.oldQuestion = JSON.parse(oldDataQuestion)
                }

                if (dataDeleted) {
                    state.deletedDataEdit = JSON.parse(dataDeleted)
                }

                let formData = {
                    quiz_name: localStorage.quiz_name_edit,
                    description: localStorage.description_edit,
                    images_cover: localStorage.images_cover_edit,
                    category_id: kategori == '1' || kategori == '2' || kategori == '3' || kategori == '4' || kategori == '5' ? '64215d46fb6ea4ab650b1307' : kategori,
                    grade_id: localStorage.grade_id_edit ? localStorage.grade_id_edit : localStorage.level_edit ? localStorage.level_edit : null,
                    sub_grade_id: localStorage.sub_grade_id_edit,
                    topic: kategori == '1' || kategori == '2' || kategori == '3' || kategori == '4' || kategori == '5' ? kategori : null
                }

                console.log(formData)

                updateQuizCreator(formData, value)
                    .then((response) => {
                        if (response.status && response.status == true) {
                            // Add new qestion
                            state.newQuestion.forEach((newData) => {
                                newData._id = null;
                                addQuestionCreator(newData, value)
                                    .then((response) => {
                                        if (response.status && response.status == true) {
                                            localStorage.removeItem("newListQuestionCreator");
                                        }
                                    })
                            })
                            state.deletedDataEdit.forEach((e) => {
                                deleteQuestionCreator(e._id)
                                    .then((response) => {
                                        if (response.status && response.status == true) {
                                            console.log(response)
                                            localStorage.removeItem("deletedEditQuestion");
                                        }
                                    })
                            })

                            // Update question
                            state.oldQuestion.forEach((oldData) => {
                                console.log("data lama ", oldData)
                                //cek if data has been deleted on array
                                if (oldData.deleted != null && oldData.deleted == true) {
                                    deleteQuestionCreator(oldData._id)
                                        .then((response) => {
                                            if (response.status && response.status == true) {
                                                console.log(response)
                                            }
                                        })
                                } else {
                                    if (oldData._id.length >= 5) { //cek if id it's not dummy
                                        putQuestionCreator(oldData, oldData._id)
                                            .then((response) => {
                                                if (response.status && response.status == true) {
                                                    localStorage.removeItem("listQuestionCreator");
                                                }
                                            })
                                    } else {
                                        oldData._id = null //id dummy set to null
                                        if (oldData.voice != null && oldData.voice != '') {
                                            oldData.question = null
                                        }

                                        if (oldData.video != null && oldData.video != '') {
                                            oldData.question = null
                                        }
                                        addQuestionCreator(oldData, value)
                                            .then((response) => {
                                                if (response.status && response.status == true) {
                                                    localStorage.removeItem("newListQuestionCreator");
                                                }
                                            })
                                    }
                                }
                            })
                            state.loadingUpdate = false

                            const length = state.listQuestion.reduce((totalDuration, question) => {
                                return totalDuration + (question.duration || 30);
                            }, 0);

                            if (localStorage.getItem("quizTime")) {
                                localStorage.removeItem("quizTime")
                            }

                            if (localStorage.getItem("sisaWaktu")) {
                                localStorage.removeItem("sisaWaktu")
                            }
                            localStorage.setItem("quizTime", length)
                            router.push({ name: 'Use-Template', query: { key: value, creator: true } })
                        } else {
                            state.loadingUpdate = false
                        }
                    })
            }
            state.dataQuiz = {
                category_id: null,
                learning_category_id: null,
                grade_id: null,
                quiz_name: null,
                author: null,
                images_cover: null,
                categories_quiz: {
                    category_name: null
                }
            }

            localStorage.removeItem("quiz_name_edit")
            localStorage.removeItem("description_edit")
            localStorage.removeItem("images_cover_edit")
            localStorage.removeItem("category_id_edit")

        },

        //simpan publish from template
        publishFromTemplate(state, id, author) {
            let dataQuestion = localStorage.getItem("listQuestion");
            state.listTemplateQuestion = JSON.parse(dataQuestion);

            if (author == '0') {
                state.formQuizCreator.is_use_template = true
            } else {
                state.formQuizCreator.is_use_template = false
            }

            state.formQuizCreator.quiz_name = localStorage.quiz_name_temp ? localStorage.quiz_name_temp : ""
            state.formQuizCreator.category_id = localStorage.category_id_temp ? localStorage.category_id_temp : ""
            state.formQuizCreator.images_cover = localStorage.images_cover_temp ? localStorage.images_cover_temp : ""
            state.formQuizCreator.description = localStorage.description_temp ? localStorage.description_temp : ""
            state.formQuizCreator.grade_id = localStorage.grade_temp ? localStorage.grade_temp : ""
            state.formQuizCreator.sub_grade_id = localStorage.sub_grade_temp ? localStorage.sub_grade_temp : ""
            state.formQuizCreator.quiz_template_id = id,
                addQuizCreator(state.formQuizCreator).then((response) => {
                    if (response.status && response.status == true) {
                        const quiz_id = response.data._id;
                        state.listTemplateQuestion.forEach((item) => {
                            item._id = null; //re-create id for new question
                            postQuestionCreator(item, response.data._id)
                                .then((response) => {
                                    if (response.status && response.status == true) {
                                        state.validationTemplate = false

                                        const length = state.listTemplateQuestion.reduce((totalDuration, question) => {
                                            return totalDuration + (question.duration || 30);
                                        }, 0);

                                        if (localStorage.getItem("quizTime")) {
                                            localStorage.removeItem("quizTime")
                                        }

                                        if (localStorage.getItem("sisaWaktu")) {
                                            localStorage.removeItem("sisaWaktu")
                                        }
                                        localStorage.setItem("quizTime", length)
                                        router.push({ name: 'Use-Template', query: { key: quiz_id, creator: true } })

                                        localStorage.removeItem("quiz_name_temp")
                                        localStorage.removeItem("description_temp")
                                        localStorage.removeItem("images_cover_temp")
                                        localStorage.removeItem("category_id_temp")
                                        localStorage.removeItem("grade_temp")
                                        localStorage.removeItem("sub_grade_temp")
                                        localStorage.removeItem("index_grade_temp")
                                    }
                                })
                        })
                    } else {
                        state.validationTemplate = true
                    }
                })
        },

        // create-questions-creator method
        simpanQuestionCreator(state, id) {
            if (state.createData.question_type == 'True or False') {
                state.createData.option_3 = null
                state.createData.option_4 = null
            }

            if (state.createData.question_type == 'Match') {
                state.createData.point_match_1 = '10'
                state.createData.point_match_2 = '10'
                state.createData.point_match_3 = '10'
                state.createData.point_match_4 = '10'
            }

            if (state.opsi1 != null) {
                state.createData.correct_answer = state.opsi1
            } else if (state.opsi2 != null) {
                state.createData.correct_answer = state.opsi2
            } else if (state.opsi3 != null) {
                state.createData.correct_answer = state.opsi3
            } else if (state.opsi4 != null) {
                state.createData.correct_answer = state.opsi4
            } else {
                state.createData.correct_answer = null;
            }
            state.createData.quiz_id = id;
            state.listCreateQuestion = state.listCreateQuestion || [];
            if (state.createData.question != null && state.createData.question != '' && state.createData._id == null) {
                var num = Math.floor(Math.random() * 90000) + 10000;//random number for dummy id
                state.createData._id = num
                state.listCreateQuestion.push(state.createData)
            } else if (state.createData._id != state.createData._id) {
                state.listCreateQuestion.push(state.createData)
            }

            state.createData = {
                question_type: 'Multiple Choice',
                quiz_id: '',
                question: '',
                option_1: '',
                option_2: '',
                option_3: '',
                option_4: '',
                voice: '',
                video: '',
                point: 10,
                duration: 30,
                correct_answer: '',
            }

            localStorage.setItem("listQuestionCreator", JSON.stringify(state.listCreateQuestion));
            // localStorage.setItem("newListQuestionCreator", JSON.stringify(state.newListCreateQuestion));

            if (id != undefined) {
                router.push({ name: "Create-My-Quiz", query: { key: id } });
            } else {
                router.push({ name: "Create-My-Quiz" });
            }
        },

        // create-questions-creator method
        simpanCreateQuestionCreator(state, id) {
            if (state.createData.question_type == 'True or False') {
                state.createData.option_3 = null
                state.createData.option_4 = null
            }

            if (state.createData.question_type == 'Match') {
                state.createData.point_match_1 = '10'
                state.createData.point_match_2 = '10'
                state.createData.point_match_3 = '10'
                state.createData.point_match_4 = '10'
            }

            if (state.opsi1 != null) {
                state.createData.correct_answer = "1"
            } else if (state.opsi2 != null) {
                state.createData.correct_answer = "2"
            } else if (state.opsi3 != null) {
                state.createData.correct_answer = "3"
            } else if (state.opsi4 != null) {
                state.createData.correct_answer = "4"
            } else {
                state.createData.correct_answer = null;
            }
            state.createData.quiz_id = id;
            state.listCreateQuestion = state.listCreateQuestion || [];

            if (state.createData.question != null && state.createData.question != '' && state.createData._id == null) {
                var num = Math.floor(Math.random() * 90000) + 10000;//random number for dummy id
                state.createData._id = num
                state.listCreateQuestion.push(state.createData)
            } else if (state.createData._id != state.createData._id) {
                state.listCreateQuestion.push(state.createData)
            }

            state.createData = {
                question_type: 'Multiple Choice',
                quiz_id: '',
                question: '',
                option_1: '',
                option_2: '',
                option_3: '',
                option_4: '',
                point: 10,
                duration: 30,
                match_1: '',
                match_2: '',
                match_3: '',
                match_4: '',
                voice: '',
                video: '',
                img_option_1: '',
                img_option_2: '',
                img_option_3: '',
                img_option_4: '',
                correct_answer: '',
            }

            localStorage.setItem("listQuestionCreate", JSON.stringify(state.listCreateQuestion));
            // localStorage.setItem("newListQuestion", JSON.stringify(state.newListCreateQuestion));
            localStorage.removeItem("questionNew")
            if (localStorage.feedback) {
                localStorage.removeItem("feedback");
            }

            if (localStorage.trans_id) {
                localStorage.removeItem("trans_id");
            }

            if (['Multiple Choice', 'True or False'].includes(state.createData.question_type)) {
                if (state.createData.question_type == 'Multiple Choice') {
                    router.push({ name: "Generative-Ai" });
                } else if (state.createData.question_type == 'True or False') {
                    router.push({ name: "Generative-Ai-True-False" });
                }
            } else {
                if (id != undefined) {
                    router.push({ name: "Create-My-Quiz", query: { key: id } });
                } else {
                    router.push({ name: "Create-My-Quiz" });
                }
            }

        },

        // update-questions-creator method
        updateQuestionsCreator(state, id) {
            if (state.updateData.question_type == 'True or False') {
                state.updateData.option_3 = null
                state.updateData.option_4 = null
            }

            if (state.updateData.question_type == 'Match') {
                state.updateData.point_match_1 = '10'
                state.updateData.point_match_2 = '10'
                state.updateData.point_match_3 = '10'
                state.updateData.point_match_4 = '10'
            }

            if (state.opsi1Update != null) {
                state.updateData.correct_answer = "1"
            } else if (state.opsi2Update != null) {
                state.updateData.correct_answer = "2"
            } else if (state.opsi3Update != null) {
                state.updateData.correct_answer = "3"
            } else if (state.opsi4Update != null) {
                state.updateData.correct_answer = "4"
            } else {
                state.updateData.correct_answer = null;
            }
            state.updateData.quiz_id = id;
            state.listUpdateQuestion = state.listUpdateQuestion || [];
            if (state.updateData.question != null && state.updateData.question != '' && state.updateData._id == null) {
                var num = Math.floor(Math.random() * 90000) + 10000;//random number for dummy id
                state.updateData._id = num
                state.listUpdateQuestion.push(state.updateData)
            } else if (state.updateData._id != state.updateData._id) {
                state.listUpdateQuestion.push(state.updateData)
            }

            state.updateData = {
                question_type: 'Multiple Choice',
                quiz_id: '',
                question: '',
                option_1: '',
                option_2: '',
                option_3: '',
                option_4: '',
                correct_answer: '',
                voice: '',
                video: ''
            },

                state.opsi1Update = null,
                state.opsi2Update = null,
                state.opsi3Update = null,
                state.opsi4Update = null,

                // this.listQuestion.push(this.formData)
                // this.newListQuestion.push(this.formData)
                localStorage.setItem("listQuestionCreator", JSON.stringify(state.listUpdateQuestion));
            // localStorage.setItem("newListQuestionCreator", JSON.stringify(state.newListUpdateQuestion));
            localStorage.removeItem("updateQuestionNew")

            if (id != undefined) {
                router.push({ name: "Edit-My-Quiz", query: { key: id } });
            } else {
                router.push({ name: "Edit-My-Quiz" });
            }
        },

        //edit quiz template
        editTemplate(state, id) {
            if (state.templateData.question_type == 'True or False') {
                state.templateData.option_3 = null
                state.templateData.option_4 = null
            }

            if (state.templateData.question_type == 'Match') {
                state.templateData.point_match_1 = '10'
                state.templateData.point_match_2 = '10'
                state.templateData.point_match_3 = '10'
                state.templateData.point_match_4 = '10'
            }

            if (state.opsi1Template != null) {
                state.templateData.correct_answer = "1"
            } else if (state.opsi2Template != null) {
                state.templateData.correct_answer = "2"
            } else if (state.opsi3Template != null) {
                state.templateData.correct_answer = "3"
            } else if (state.opsi4Template != null) {
                state.templateData.correct_answer = "4"
            } else {
                state.templateData.correct_answer = null;
            }

            state.listTemplateQuestion = state.listTemplateQuestion || [];

            if (state.templateData._id != state.templateData._id) {
                state.listTemplateQuestion.push(state.templateData)
            }

            localStorage.setItem("listQuestion", JSON.stringify(state.listTemplateQuestion));

            if (id != undefined) {
                router.push({ name: "Edit-Template", query: { key: id } });
            }
        },

        // create-questions-polling-creator method
        simpanCreateQuestionPolling(state, id) {
            if (state.createPollingData.question_type == 'True or False') {
                state.createPollingData.option_3 = null
                state.createPollingData.option_4 = null
            }

            if (state.opsi1PollingCreate != null) {
                state.createPollingData.correct_answer = "1"
            } else if (state.opsi2PollingCreate != null) {
                state.createPollingData.correct_answer = "2"
            } else if (state.opsi3PollingCreate != null) {
                state.createPollingData.correct_answer = "3"
            } else if (state.opsi4PollingCreate != null) {
                state.createPollingData.correct_answer = "4"
            } else {
                state.createPollingData.correct_answer = null;
            }
            state.createPollingData.quiz_id = id;
            state.listCreatePollingQuestion = state.listCreatePollingQuestion || [];
            if (state.createPollingData.question != null && state.createPollingData.question != '' && state.createPollingData._id == null) {
                var num = Math.floor(Math.random() * 90000) + 10000;//random number for dummy id
                state.createPollingData._id = num
                state.listCreatePollingQuestion.push(state.createPollingData)
            } else if (state.createPollingData._id != state.createPollingData._id) {
                state.listCreatePollingQuestion.push(state.createPollingData)
            }

            state.createPollingData = {
                question_type: 'Multiple Choice',
                quiz_id: '',
                question: '',
                option_1: '',
                option_2: '',
                option_3: '',
                option_4: '',
                correct_answer: '',
            }

            localStorage.setItem("listQuestionCreatePolling", JSON.stringify(state.listCreatePollingQuestion));
            localStorage.removeItem("questionNewPolling")


            if (id != undefined) {
                router.push({ name: "Setup-Polling", query: { key: id } });
            } else {
                router.push({ name: "Setup-Polling" });
            }
        },

        //Publosh Create Polling
        publishCreatePolling() {
            // state.loadingCreate = true;
            // let vm = this
            let formData = {
                polling_name: localStorage.quiz_name_polling,
                // description: localStorage.quiz_description,
                // images_cover: localStorage.images_cover,
                // category_id: localStorage.category_id
            }

            let listQuestion = JSON.parse(localStorage.listQuestionCreatePolling)
            addPolling(formData)
                .then((response) => {
                    if (response.status && response.status == true) {
                        console.log("Jak", response.status)
                        listQuestion.forEach((item) => {
                            item._id = null; //re-create id for new question
                            addQuestionPolling(item, response.data._id)
                                .then((response) => {
                                    if (response.status && response.status == true) {
                                        console.log(response)
                                    } else {
                                        console.log(response)
                                    }
                                })
                        })
                        // state.loadingCreate = false;
                        var id_quiz = response.data._id

                        const length = listQuestion.reduce((totalDuration, question) => {
                            return totalDuration + (question.duration || 30);
                        }, 0);

                        if (localStorage.getItem("quizTime")) {
                            localStorage.removeItem("quizTime")
                        }

                        if (localStorage.getItem("sisaWaktu")) {
                            localStorage.removeItem("sisaWaktu")
                        }

                        localStorage.setItem("quizTime", length)
                        router.push({ name: 'Use-Template', query: { key: id_quiz, creator: true } })
                    } else {
                        console.log(response)
                        // state.loadingCreate = false;
                    }
                })

            localStorage.setItem("listQuestionCreatePolling", "")
            localStorage.setItem("quiz_name_polling_polling", "")
            localStorage.setItem("quiz_description_polling", "")
            localStorage.setItem("images_cover_polling", "")
            localStorage.setItem("category_id_polling", "")
            // localStorage.setItem("createTag", "")
        },

        searchEngine(state, value) {
            if (value != null && value != "") {
                state.searchStat = true;
                state.dataTop = [];
                state.dataCreator = [];
                state.dataTag = [
                    {
                        category_id: null,
                        category_name: null,
                        _id: null,
                        data_quiz_all: [],
                    },
                ]

                let usersLogin = localStorage.getItem("users");
                let usersNotLogin = sessionStorage.getItem("users_id")

                let data = {}
                if (usersLogin) {
                    let parseUser = JSON.parse(usersLogin);

                    data = { search: state.filterSearchEngine, user_id: parseUser._id };
                } else if (usersNotLogin) {
                    data = { search: state.filterSearchEngine, user_not_login_id: usersNotLogin };
                } else {
                    data = { search: state.filterSearchEngine };
                }
                state.loadingSe = true;
                getSearchEngine(data).then((response) => {
                    if (response.status && response.status == true) {
                        //Data TOP
                        if (response.data.top.length > 0) {
                            let topTemplate = response.data.top[0];
                            topTemplate.forEach((e) => {
                                state.dataTop.push(e);
                            });

                            // cek if data top length more then 1
                            if (response.data.top.length > 1) {
                                let topNoTemplate = response.data.top[1];
                                topNoTemplate.forEach((e) => {
                                    state.dataTop.push(e);
                                });
                            }
                        }

                        //Data Creator
                        state.dataCreator = response.data.creator;

                        //Data Tag
                        let tags = response.data.tags;
                        let index = 0;
                        if (index + 1 <= tags.length) {
                            tags.forEach((e) => {
                                state.dataTag[index].category_id = e.category_id;
                                state.dataTag[index].category_name = e.category_name;
                                state.dataTag[index].data_quiz_all = e.data_quiz_template.concat(e.data_quiz_creator)

                                state.dataTag.push(e)
                                index++;
                            });
                        }
                        state.dataTag.splice(index, 1)

                        state.loadingSe = false;
                    } else {
                        state.loadingSe = false;
                    }
                });
            } else {
                state.searchStat = false;
            }
        },

        nextGenerativeAi(state) {
            let formData = {
                content_type: 'quiz'
            };

            localStorage.removeItem("quiz_name")
            localStorage.removeItem("quiz_description")
            localStorage.removeItem("images_cover")
            // localStorage.removeItem("category_id")
            localStorage.removeItem("createTag")
            localStorage.removeItem("materiAi")
            localStorage.removeItem("gradeAi")
            localStorage.removeItem('lengthQuestionAi')
            // localStorage.removeItem("subGradeAi")
            localStorage.removeItem("descriptionAi")
            localStorage.removeItem("category_name")
            localStorage.removeItem("category_id_Backup")

            if (localStorage.feedback != undefined) {
                localStorage.removeItem("feedback")
            }
            if (localStorage.trans_id != undefined) {
                localStorage.removeItem("trans_id")
            }

            // localStorage.removeItem("grade")
            // localStorage.removeItem("sub_grade")
            // localStorage.removeItem("index_grade")

            postContent(formData)
                .then((response) => {
                    if (response.status && response.status == true) {
                        state.generatedQuiz = false;
                        router.push({ name: 'Create-My-Quiz' })
                    }
                })
        },

        setIsTtqGenerated(state, status) {
            state.isTtqGenerated = status;
        },

    },
    actions: {
    },
    getters: {
        loadingCreate: state => state.loadingCreate,
        loadingUpdate: state => state.loadingUpdate,
        generatedQuiz: state => state.generatedQuiz,
        generatedAudioContent: state => state.generatedAudioContent
    },
    modules: {
        users,
        AuthorQuiz
    }
})
