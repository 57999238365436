import Vue from 'vue'
import Vuesax from 'vuesax'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import $ from "jquery"
import i18n from './i18n'

//------------------ Styles

import 'vuesax/dist/vuesax.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'material-icons/iconfont/material-icons.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEyeSlash, faTrashCan, faFileAlt, faEye } from '@fortawesome/free-regular-svg-icons'
import { faFileDownload, faPen, faSquareRootVariable, faBold, faItalic, faUnderline, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faTrash, faImage, faUpload, faA, faEyeDropper, faLinkSlash, faExpand, faCompress, faPlay, faPause, faChevronLeft, faChevronRight, faChevronUp, faChevronDown, faPlus, faWandMagicSparkles, faCheck, faGear, faBackwardStep, faForwardStep, faBars, faInfo, faQuestion, faMagnifyingGlass, faEllipsisVertical, faCirclePlay, faCirclePause, faCircle, faStop, faVolumeHigh, faUserPlus, faCircleMinus, faXmark, faSpinner, faArrowDown, faArrowRight, faEllipsis, faEyedropper, faMinus, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faPaperclip, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//------------------ Scripts
// import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.min.js'

// ----------------- Otp Input component
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

//------------------ Theme Configurations
import 'prismjs'
import 'prismjs/themes/prism.css'
import VsPrism from '@/components/prism/VsPrism.vue';
Vue.component(VsPrism.name, VsPrism);

// //------------------- Sentry Io
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

// ----------------- color picker
import { Slider, Sketch } from 'vue-color';
Vue.component('SketchPicker', Sketch);
Vue.component('SliderPicker', Slider);

//------------------ library
import VueClipboard from 'vue-clipboard2'
import VueApexCharts from 'vue-apexcharts'
import AVPlugin from 'vue-audio-visual'
import browserDetect from "vue-browser-detect-plugin";
import * as Fingerprint2 from 'fingerprintjs2'
Vue.prototype.$fingerprint = Fingerprint2;

// ----------------- tour
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
//------------------ draggable component
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

Vue.component('vue-draggable-resizable', VueDraggableResizable)

//------------------ Vue Modal
import VModal from 'vue-js-modal';
Vue.use(VModal);

//------------------ Vue Tippy
import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

//------------------ Vue Katex
import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';
Vue.use(VueKatex);

// VUE MASK
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

//-------------- config element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// import 'element-ui/lib/locale/lang/en'

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// configure language
locale.use(lang)

//------------------ Mathquill
import 'mathquill-node/lib/mathquill.css';

// let versi = require("../package.json");

// if(Vue.config.devtools == false){
//   Sentry.init({
//       Vue,
//       release: "soca-proctoring@" + versi.version,
//       dsn: "https://713b41d9d81f4039a80f9740517e9bb1@o1145325.ingest.sentry.io/6214870",
//       environment: "production",
//       autoSessionTracking: false,
//       integrations: [
//           new BrowserTracing({
//               routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//               tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//           }),
//       ],
//       debug : false,
//       tracesSampleRate: 1.0,
//   });
// }
VueClipboard.config.autoSetContainer = true

Vue.use(VueApexCharts)
Vue.use(AVPlugin)
Vue.use(ElementUI)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(browserDetect);
Vue.use(Vuesax, {
  // options here
})
Vue.use(VueClipboard)
Vue.use(VueMeta)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
window.axios = require('axios')
window.$ = window.jQuery = require('jquery');
library.add(faEyeSlash, faTrashCan, faFileAlt, faEye, faFileDownload, faPen, faSquareRootVariable, faBold, faItalic, faUnderline, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faTrash, faImage, faUpload, faA, faEyeDropper, faLinkSlash, faExpand, faCompress, faPlay, faPause, faChevronLeft, faChevronRight, faChevronUp, faChevronDown, faPlus, faWandMagicSparkles, faCheck, faGear, faBackwardStep, faForwardStep, faBars, faInfo, faQuestion, faMagnifyingGlass, faEllipsisVertical, faCirclePlay, faCirclePause, faCircle, faStop, faVolumeHigh, faUserPlus, faCircleMinus ,faXmark, faSpinner, faArrowDown, faArrowRight, faEllipsis, faEyedropper, faMinus, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faPaperclip, faArrowRightLong)


$(document).ready(function() {
  $("body").tooltip({ selector: '[data-toggle=tooltip]' });
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

import '@/assets/scss/style.scss'
