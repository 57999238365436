import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getQuiz(data) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuiz,
    params: data
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
      page: res.data.page,
      limit: res.data.limit,
      totalPage: res.data.totalPage,
      totalData: res.data.totalData,
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizAll(data) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuiz + '/all',
    params: data
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizSimilar(id) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuiz + '/similar/' + id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizById(id, params) {
  return axios.get(ApiRoute.getQuiz + '/' + id, { params: params })
    .then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message,
      }
      return data;
    }).catch((error) => {
      const data = {
        status: false,
        response: error
      }
      return data;
    })
}

export async function addQuiz(formData) {
  return axios.post(ApiRoute.getQuiz, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function updateQuiz(formData, id) {
  return axios.put(ApiRoute.getQuiz + '/' + id, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function deleteQuiz(id) {
  return axios.delete(ApiRoute.getQuiz + '/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })

}

export async function getUserRatingTemplate(id) {
  return axios.get(ApiRoute.getQuiz + '/check-rating/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function templateUserRating(id) {
  return axios.put(ApiRoute.getQuiz + '/rating/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function templateUserUnrating(id) {
  return axios.put(ApiRoute.getQuiz + '/unrating/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function reportQuiz(id, formData) {
  return axios.post(ApiRoute.reportQuiz + '/' + id, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function getPlay(id, params) {
  return axios.get(ApiRoute.getQuiz + '/play/' + id, { params: params })
    .then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message,
      }
      return data;
    }).catch((error) => {
      const data = {
        status: false,
        response: error
      }
      return data;
    })
}

export async function getEditTemplate(id, params) {
  return axios.get(ApiRoute.getQuiz + '/edit-template/' + id, { params: params })
    .then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message,
      }
      return data;
    }).catch((error) => {
      const data = {
        status: false,
        response: error
      }
      return data;
    })
}

export async function getUseTemplate(id, params) {
  return axios.get(ApiRoute.getQuiz + '/use-template/' + id, { params: params })
    .then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message,
      }
      return data;
    }).catch((error) => {
      const data = {
        status: false,
        response: error
      }
      return data;
    })
}

export async function getQuizPopuler(params) {
  return axios.get(ApiRoute.getQuizPopuler, { params: params })
    .then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message,
      }
      return data;
    }).catch((error) => {
      const data = {
        status: false,
        response: error
      }
      return data;
    })
}
export async function getQuizTemplate(data, search) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuiz + '-template?search=' + search,
    // params: data
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizTemplateSearch(search) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuiz + '-template?search=' + search,
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}