import axios from "../../config/axios";
import ApiRoute from "../api.route";
import { base_url_landing_page, base_url }  from "../../config/base_url";

export async function getDetailPaymentSwc(id) {
    return axios.get(base_url_landing_page + ApiRoute.swcPay + '/detail/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function createSwcPayment(payload) {
	return axios.post(base_url_landing_page + ApiRoute.swcPay + '/create', payload)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function confirmPin(payload) {
	return axios.post(base_url_landing_page + ApiRoute.swcPay + '/confirm', payload)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function customSwcRequest(payload) {
	return axios.post(base_url_landing_page + ApiRoute.subscriptions + '/create-swc-request', payload)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function checkPriceSwc() {
    return axios.get(base_url_landing_page + ApiRoute.swcPay + '/check-price')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function getPackageTopupSwc() {
    return axios.get(base_url + '/api/package-top-up-swc')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function paymentRemoveWM(formData, id) {
    return axios.post(base_url_landing_page + ApiRoute.swcPay + '/remove-watermark/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}