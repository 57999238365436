<template>
    <div>
        <b-modal id="otp-terkirim" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="next"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/peace_sovia.png" alt="Sovia" width="150px">
                <div class="mt-3 text-white">
                    <h5 class="font-weight-normal mt-2" style="font-size:14px">Your OTP code has been sent to <br> your email. Please check and enter <br> the code to continue.</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-primary btn-purple-nb" @click="next"><p class="mx-2 mb-0">Oke</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    setup() {
        
    },

    methods : {
        next(){
            if(this.$route.name == 'Forgot-Password') {
                if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                    this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                    this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                    this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                } else {
                    this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password'} })
                }
            } else {
                if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                    this.$router.push({ name : 'Verifikasi', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                    this.$router.push({ name : 'Verifikasi', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                    this.$router.push({ name : 'Verifikasi', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                } else {
                    this.$router.push({ name : 'Verifikasi' })
                }
            }
        }
    }
}
</script>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#otp-terkirim .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#otp-terkirim .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#otp-terkirim .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

.btn-purple {
  background-color: #6d75f6;
  border: 1px solid #6d75f6;
  color: white;
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}
</style>