import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from '../config/authrequired'
// import CheckToken from '../config/check_token'

// LANDING PAGE COMPONENT
import SignUp from "../views/pages/landing-page/sign-up/SignUp.vue"
import SignIn from "../views/pages/landing-page/sign-in/SignIn.vue"
import ForgotPassword from "../views/pages/landing-page/forgot-password/ForgotPassword.vue"
import NewPassword from "../views/pages/landing-page/new-password/NewPassword.vue"
import Verifikasi from "../views/pages/landing-page/verifikasi/Verifikasi.vue"
import SelectRole from "../views/pages/landing-page/select-role/SelectRole.vue"
import ChooseType from "../views/pages/landing-page/select-type/SelectType.vue"
import MobileModal from "../components/navbar-components/MobileModal.vue"


Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        // ======================================
        // Router Soca.AI Test Web Creator
        // ======================================

        // FOR SET USER ID ONLY
        // {
        //     path: '/',
        //     name: 'landing-page',
        //     component: () => import('@/views/pages/landing-page/Home.vue')
        // },
        // {
        //     path: '/',
        //     redirect: 'landing-page',
        //     beforeEnter : CheckToken, //Check Token client
        //     component: () => import('@/views/pages/landing-page/index.vue'),
        //     children: [
        //         {
        //             path: '/',
        //             name: 'landing-page',
        //             component: {
        //                 render(c) {
        //                     return c('router-view')
        //                 }
        //             },
        //             children: [
        //                 {
        //                     path: '/',
        //                     name: 'landing-page',
        //                     component: () => import('@/views/pages/landing-page/Home.vue')
        //                 },
        //                 {
        //                     path: '/m-multilingual-promo',
        //                     name: 'M-Multilingual-Promo',
        //                     component: () => import('@/views/pages/landing-page/multilingual-promo/index-mobile.vue')
        //                 },
        //                 {
        //                     path: '/multilingual-promo',
        //                     name: 'Multilingual-Promo',
        //                     component: () => import('@/views/pages/landing-page/multilingual-promo/index.vue')
        //                 },
        //                 {
        //                     path: '/pricing-plans',
        //                     name: 'Pricing-Plans',
        //                     component: () => import('@/views/pages/landing-page/pricing-plans/index.vue')
        //                 },
        //                 {
        //                     path: '/products/speech-editor',
        //                     name: 'Speech-Editor',
        //                     component: () => import('@/views/pages/landing-page/product/speech-editor/index.vue')
        //                 },

        //                 {
        //                     path: '/products/creative-studio',
        //                     name: 'Creative-Studio',
        //                     component: () => import('@/views/pages/landing-page/product/creative-studio/index.vue')
        //                 },

        //                 {
        //                     path: '/products/dubbing-studio',
        //                     name: 'Dubbing Studio',
        //                     component: () => import('@/views/pages/landing-page/product/dubbing-studio/index.vue')
        //                 },

        //                 {
        //                     path: '/products/text-to-quiz',
        //                     name: 'Text-To-Quiz',
        //                     component: () => import('@/views/pages/landing-page/product/text-to-quiz/index.vue')
        //                 },
        //                 {
        //                     path: '/products/text-to-video',
        //                     name: 'Text-To-Video',
        //                     component: () => import('@/views/pages/landing-page/product/text-to-video/index.vue')
        //                 },
        //                 {
        //                     path: '/products/text-to-audio',
        //                     name: 'Text-To-Audio',
        //                     component: () => import('@/views/pages/landing-page/product/text-to-audio/index.vue')
        //                 },
        //                 {
        //                     path: '/products/generate-voices',
        //                     name: 'Generate-voices',
        //                     component: () => import('@/views/pages/landing-page/product/generate-voices/index.vue')
        //                 },
        //                 {
        //                     path: '/products/generate-videos',
        //                     name: 'Generate-videos',
        //                     component: () => import('@/views/pages/landing-page/product/generate-videos/index.vue')
        //                 },
        //                 {
        //                     path: '/products/generate-quizzes',
        //                     name: 'Generate-quizzes',
        //                     component: () => import('@/views/pages/landing-page/product/generate-quizzes/index.vue')
        //                 },
        //                 {
        //                     path: '/products/clone-voices',
        //                     name: 'Clone-voices',
        //                     component: () => import('@/views/pages/landing-page/product/clone-voices/index.vue')
        //                 },
        //                 {
        //                     path: '/products/ai-proctoring',
        //                     name: 'Ai-Proctoring',
        //                     component: () => import('@/views/pages/landing-page/product/ai-proctoring/index.vue')
        //                 },
        //                 {
        //                     path: '/products/library',
        //                     name: 'Library',
        //                     component: () => import('@/views/pages/landing-page/product/library/index.vue')
        //                 },
        //                 {
        //                     path: '/products/library/detail-quiz',
        //                     name: 'Detail-Library',
        //                     component: () => import('@/views/pages/landing-page/product/library/detail-quiz/index.vue')
        //                 },
        //                 {
        //                     path: '/products/library/detail-audio',
        //                     name: 'Detail-Library-Audio',
        //                     component: () => import('@/views/pages/landing-page/product/library/detail-audio/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/marketing-&-sales',
        //                     name: 'Marketing & Sales',
        //                     component: () => import('@/views/pages/landing-page/solution/marketing/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/talent-development',
        //                     name: 'Talent Development',
        //                     component: () => import('@/views/pages/landing-page/solution/talentDev/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/creative-agencies',
        //                     name: 'Creative Agencies',
        //                     component: () => import('@/views/pages/landing-page/solution/creative-agencies/index.vue')
        //                 },
        //                  {
        //                     path: '/solutions/teacher-&-school',
        //                     name: 'Education for Teacher & School',
        //                     component: () => import('@/views/pages/landing-page/solution/education-teacher&school/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/lms',
        //                     name: 'LMS Intergation',
        //                     component: () => import('@/views/pages/landing-page/solution/lms/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/higher-education',
        //                     name: 'Higher Education',
        //                     component: () => import('@/views/pages/landing-page/solution/higher-education/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/trainer&assessor',
        //                     name: 'Trainer & Assessor',
        //                     component: () => import('@/views/pages/landing-page/solution/Assessor/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/dubbing-for-movie',
        //                     name: 'DubbingForMovie',
        //                     component: () => import('@/views/pages/landing-page/solution/dubbing-for-movie/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/ai-readers-for-publishers',
        //                     name: 'AiReaders',
        //                     component: () => import('@/views/pages/landing-page/solution/ai-readers/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/voice-characters-for-game',
        //                     name: 'Voice-Characters',
        //                     component: () => import('@/views/pages/landing-page/solution/voice-characters/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/cx-conversation-for-chatbot',
        //                     name: 'CxConversation',
        //                     component: () => import('@/views/pages/landing-page/solution/cx/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/multilingual-storyteller',
        //                     name: 'MultilingualStoryteller',
        //                     component: () => import('@/views/pages/landing-page/solution/multilingual-strotyteller/index.vue')
        //                 },
        //                 {
        //                     path: '/solutions/for-marketing-sales',
        //                     name: 'forMarketingSales',
        //                     component: () => import('@/views/pages/landing-page/solution/for-marketing/index.vue')
        //                 },

        //                 {
        //                     path: '/contact-us',
        //                     name: 'Contact-Us',
        //                     component: () => import('@/views/pages/landing-page/contact-us/index.vue')
        //                 },
        //                 {
        //                     path: '/faq',
        //                     name: 'Faq',
        //                     component: () => import('@/views/pages/landing-page/faq/index.vue')
        //                 },
        //                 {
        //                     path: '/about-us',
        //                     name: 'About-Us',
        //                     component: () => import('@/views/pages/landing-page/about-us/index.vue')
        //                 },
        //                 {
        //                     path: '/careers',
        //                     name: 'Careers',
        //                     component: () => import('@/views/pages/landing-page/careers/index.vue')
        //                 },
        //                 {
        //                     path: '/careers/detail/ai-angineer',
        //                     name: 'detail-ai-angineer',
        //                     component: () => import('@/views/pages/landing-page/careers/detail-ai-engineer.vue')
        //                 },
        //                 {
        //                     path: '/blog',
        //                     name: 'Blog',
        //                     component: () => import('@/views/pages/landing-page/blog/index.vue')
        //                 },
        //                 {
        //                     path: '/blog/detail/soca-interact',
        //                     name: 'Blog',
        //                     component: () => import('@/views/pages/landing-page/blog/detail-blog-soca-interact.vue')
        //                 },
        //                 {
        //                     path: '/terms-conditions',
        //                     name: 'terms-conditions',
        //                     component: () => import('@/views/pages/settings/TermsConditionsLandingPage.vue')
        //                 },
        //                 {
        //                     path: 'share/:name/:id',
        //                     name: 'Preview-Audio-Content',
        //                     component: () => import(/* webpackChunkName: "publish-text-to-audio" */'@/views/pages/generative-ai/text-to-audio/PreviewShare.vue')
        //                 },
        //                 {
        //                     path: '/solutions/media-publishing',
        //                     name: 'Media-Publishing',
        //                     component: () => import('@/views/pages/landing-page/solution/media-publishing/index.vue')
        //                 },
        //                 {
        //                     path: '/multilingual-promo',
        //                     name: 'Multilingual-Promo',
        //                     component: () => import('@/views/pages/landing-page/multilingual-promo/index.vue')
        //                 },
        //             ]
        //         }
        //     ]
        // },  
        // {
        //     path: '/auth-layout',
        //     name: 'AuthLayout',
        //     component: () => import(/* webpackChunkName: "template-quiz" */'@/views/pages/auth/layout.vue')
        // },
        {
            path: '/share/:name/:id',
            name: 'Preview-Audio-Content',
            component: () => import(/* webpackChunkName: "share-text-to-audio" */'@/views/pages/generative-ai/text-to-audio/PreviewShare.vue')
        },
        {
            path: '/login',
            redirect: 'login',
            component: () => import('@/views/pages/auth/layout.vue'),
            children: [
                {
                    path: '/login',
                    name: 'SignIn',
                    component: SignIn
                },
                {
                    path: '/forgot-password',
                    name: 'Forgot-Password',
                    component: ForgotPassword
                },
                {
                    path: '/sign-up',
                    name: 'SignUp',
                    component: SignUp
                },
                {
                    path: '/verifikasi',
                    name: 'Verifikasi',
                    component: Verifikasi
                },
                {
                    path: '/new-password',
                    name: 'New-Password',
                    component: NewPassword
                },
            ]
        },
        {
            path: '/',
            redirect: 'home',
            beforeEnter: AuthRequired, //Check Token client
            component: () => import('@/views/MainContainer.vue'),
            children: [
                {
                    path: 'templates',
                    name: 'Templates',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Templates',
                            component: () => import(/* webpackChunkName: "template-quiz" */'@/views/pages/template/Templates.vue')
                        },
                        {
                            path: 'quiz',
                            name: 'Quiz',
                            component: () => import(/* webpackChunkName: "template-quiz" */'@/views/pages/quiz/quiz.vue')
                        },
                        {
                            path: 'see-all',
                            name: 'See-All',
                            component: () => import(/* webpackChunkName: "see-all" */'@/views/pages/quiz/lihat-semua/index.vue')
                        },
                        {
                            path: 'detail-quiz/:id/:author?/:name',
                            name: 'Detail-Quiz',
                            component: () => import(/* webpackChunkName: "detail-quiz" */'@/views/pages/quiz/detailQuiz.vue')
                        },
                        {
                            path: 'edit-template',
                            name: 'Edit-Template',
                            component: () => import(/* webpackChunkName: "edit-template" */'@/views/pages/quiz/editTemplate.vue')
                        },
                        {
                            path: 'publish-quiz',
                            name: 'Use-Template',
                            component: () => import(/* webpackChunkName: "use-template" */'@/views/pages/quiz/gunakanTemplate.vue')
                        },
                        {
                            path: 'live-quiz',
                            name: 'Live-Quiz',
                            component: () => import(/* webpackChunkName: "live-quiz" */'@/views/pages/quiz/liveQuiz.vue')
                        },
                        {
                            path: 'leaderboard-quiz',
                            name: 'Leaderboard-Quiz',
                            beforeEnter: AuthRequired,
                            component: () => import(/* webpackChunkName: "leaderboard-quiz" */'@/views/pages/quiz/leaderboard.vue')
                        },
                        {
                            path: 'leaderboard-final',
                            name: 'Leaderboard-Final',
                            component: () => import(/* webpackChunkName: "leaderboard-quiz" */'@/views/pages/quiz/leaderboard-final/index.vue')
                        },
                        {
                            path: 'edit-quiz',
                            name: 'Edit-Quiz',
                            component: () => import(/* webpackChunkName: "edit-quiz" */'@/views/pages/quiz-saya/editQuiz.vue')
                        },
                        // {
                        //     path: 'publish-quiz',
                        //     name: 'Publish-Quiz',
                        //     beforeEnter: AuthRequired,
                        //     component: () => import(/* webpackChunkName: "publish-quiz" */'@/views/pages/quiz/publishQuiz.vue')
                        // },
                        {
                            path: 'setup-quiz',
                            name: 'Setup-Quiz',
                            component: () => import(/* webpackChunkName: "setup-quiz" */'@/views/pages/setup-quiz/SetupQuiz.vue')
                        },
                        {
                            path: 'detail-setup-quiz',
                            name: 'Detail-Setup-Quiz',
                            component: () => import(/* webpackChunkName: "detail-setup-quiz" */'@/views/pages/setup-quiz/DetailSetupQuiz.vue'),
                            layout: 'none'
                        },
                        {
                            path: 'create-content',
                            name: 'Create-Content',
                            component: () => import(/* webpackChunkName: "create-content" */'@/views/pages/create-quiz/createContent.vue')
                        },
                        {
                            path: 'create-question',
                            name: 'Create-Question',
                            component: () => import(/* webpackChunkName: "create-question" */'@/views/pages/create-quiz/createQuiz.vue')
                        },
                        {
                            path: 'create-question-creator',
                            name: 'Create-Question-Creator',
                            component: () => import(/* webpackChunkName: "create-question-creator" */'@/views/pages/create-quiz/createQuizCreator.vue')
                        },
                        {
                            path: 'update-question-creator',
                            name: 'Update-Question-Creator',
                            component: () => import(/* webpackChunkName: "update-question-creator" */'@/views/pages/create-quiz/updateQuizCreator.vue')
                        },
                        {
                            path: 'setup-polling',
                            name: 'Setup-Polling',
                            component: () => import(/* webpackChunkName: "setup-polling" */'@/views/pages/polling/SetupPolling.vue')
                        },
                        {
                            path: 'create-question-polling',
                            name: 'Create-Question-Polling',
                            component: () => import(/* webpackChunkName: "create-question-polling" */'@/views/pages/polling/CreateQuestionPolling.vue')
                        },
                    ]
                },
                {
                    path: 'projects',
                    name: 'My-Quiz',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'My-Quiz',
                            component: () => import(/* webpackChunkName: "my-quiz" */'@/views/pages/quiz-saya/quizSaya.vue')
                        },
                        {
                            path: 'edit-my-quiz',
                            name: 'Edit-My-Quiz',
                            component: () => import(/* webpackChunkName: "edit-my-quiz" */'@/views/pages/quiz-saya/editQuiz.vue')
                        },
                        {
                            path: 'create-my-quiz',
                            name: 'Create-My-Quiz',
                            component: () => import(/* webpackChunkName: "edit-my-quiz" */'@/views/pages/quiz-saya/createQuestion.vue')
                        }
                    ]
                },
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Dashboard',
                            component: () => import(/* webpackChunkName: "dashboard" */'@/views/pages/dashboard/Dashboard.vue')
                        },
                        {
                            path: 'detail',
                            name: 'Detail-Dashboard',
                            component: () => import(/* webpackChunkName: "detail-dashboard" */'@/views/pages/dashboard/detail/DetailDashboard.vue')
                        }
                    ]
                },
                {
                    path: 'home',
                    name: 'Home',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Home',
                            meta: {
                                gtm: {
                                    event: ""
                                }
                            },
                            component: () => import(/* webpackChunkName: "home" */'@/views/pages/home/index.vue')
                        },
                        {
                            path: '/watch-video-tutorial',
                            name: 'WatchVideo',
                            meta: {
                                gtm: {
                                    event: ""
                                }
                            },
                            component: () => import(/* webpackChunkName: "home" */'@/views/pages/home/page/WatchVideo.vue')
                        },
                    ]
                },
                {
                    path: 'my-assets',
                    name: 'My-Assets',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'My-Assets',
                            component: () => import(/* webpackChunkName: "My Assets" */'@/views/pages/my-assets/index.vue')
                        },
                    ]
                },
                {
                    path: 'plugins',
                    name: 'Plugins',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Plugins',
                            component: () => import(/* webpackChunkName: "plugins" */'@/views/pages/plugins/index.vue')
                        },
                    ]
                },
                {
                    path: 'voice-cloning',
                    name: 'Personal-Voice',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Personal-Voice',
                            component: () => import(/* webpackChunkName: "personal-voices" */'@/views/pages/videos/personal-voices/index.vue')
                        },
                    ]
                },
                {
                    path: 'text-to-audio/:mode/:id',
                    name: 'Generate-Text-To-Audio',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Generate-Text-To-Audio',
                            component: () => import(/* webpackChunkName: "text-to-audio" */'@/views/pages/generative-ai/text-to-audio/GenerateTextToAudio.vue')
                        },
                        {
                            path: 'edit-text-to-audio',
                            name: 'Edit-Text-To-Audio',
                            component: () => import(/* webpackChunkName: "edit-text-to-audio" */'@/views/pages/generative-ai/text-to-audio/EditTextToAudio.vue')
                        },
                        {
                            path: 'publish-text-to-audio',
                            name: 'Publish-Text-To-Audio',
                            component: () => import(/* webpackChunkName: "publish-text-to-audio" */'@/views/pages/generative-ai/text-to-audio/PublishTextToAudio.vue')
                        },
                    ]
                },
                {
                    path: 'report',
                    name: 'Report',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Report',
                            component: () => import(/* webpackChunkName: "report" */'@/views/pages/report/Report.vue')
                        },
                        {
                            path: 'quiz/detail-report',
                            name: 'Detail-Report',
                            component: () => import(/* webpackChunkName: "detail-report" */'@/views/pages/report/DetailLaporan.vue')
                        },
                        {
                            path: 'detail-report/detail-report-peserta',
                            name: 'Detail-Report-Peserta',
                            component: () => import(/* webpackChunkName: "detail-report-peserta" */'@/views/pages/report/DetailLaporanPeserta.vue')
                        },
                        {
                            path: 'polling',
                            name: 'Report-Polling',
                            component: () => import(/* webpackChunkName: "detail-report-peserta" */'@/views/pages/report/polling/index.vue')
                        }
                    ]
                },
                {
                    path: 'profile-creator',
                    name: 'Profile-Creator',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Profile-Creator',
                            component: () => import(/* webpackChunkName: "profile-creator" */'@/views/pages/profile/profile-creator/index.vue')
                        },
                    ]
                },
                {
                    path: 'my-account',
                    name: 'MyAccount',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/my-account',
                            name: 'MyAccount',
                            component: () => import(/* webpackChunkName: "settings" */'@/views/pages/settings/Settings.vue')
                        },
                    ]
                },
                {
                    path: 'api-log',
                    name: 'ApiLog',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/api-log',
                            name: 'ApiLog',
                            component: () => import(/* webpackChunkName: "APILOG" */'@/views/pages/settings/LogAPI.vue')
                        },
                    ]
                },
                {
                    path: 'all-notifications',
                    name: 'All-Notifications',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'All-Notifications',
                            component: () => import(/* webpackChunkName: "all-notifications" */'@/views/pages/notifications/index.vue')
                        },
                    ]
                },
                {
                    path: 'terms-&-conditions',
                    name: 'Terms-Conditions',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Terms-Conditions',
                            component: () => import(/* webpackChunkName: "terms" */'@/views/pages/settings/TermsConditions.vue')
                        },
                        {
                            path: 'sentiment-analysis',
                            name: 'Terms-Conditions-Sentiment-Analysis',
                            component: () => import('@/views/pages/settings/TermsConditionsSentimentAnalysis')
                        }
                    ]
                },
                {
                    path: 'subscriptions',
                    name: 'Subscriptions',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Subscriptions',
                            component: () => import(/* webpackChunkName: "Subscriptions" */'@/views/pages/subscriptions/index.vue')
                        },
                    ]
                },
                {
                    path: 'payment-method',
                    name: 'PaymentMethod',
                    beforeEnter: AuthRequired,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'PaymentMethod',
                            component: () => import(/* webpackChunkName: "PaymentMethod" */'@/views/pages/topup-swc/payment/payment-method.vue')
                        },
                    ]
                },
                {
                    path: 'my-course',
                    name: 'My-Course',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'My-Course',
                            component: () => import(/* webpackChunkName: "my-quiz" */'@/views/pages/course-saya/courseSaya.vue')
                        },
                        {
                            path: 'edit-my-course',
                            name: 'Edit-My-Course',
                            component: () => import(/* webpackChunkName: "edit-my-quiz" */'@/views/pages/course-saya/editCourse.vue')
                        },
                        {
                            path: 'create-my-course',
                            name: 'Create-My-Course',
                            component: () => import(/* webpackChunkName: "edit-my-quiz" */'@/views/pages/course-saya/createCourse.vue')
                        },
                        {
                            path: 'preview-my-course',
                            name: 'Preview-My-Course',
                            // beforeEnter: AuthRequired,
                            component: () => import(/* webpackChunkName: "edit-my-quiz" */'@/views/pages/course-saya/previewCourse.vue')
                        }
                    ]
                },
                {
                    path: 'my-rewards',
                    name: 'MyRewards',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'MyRewards',
                            component: () => import(/* webpackChunkName: "MyRewards" */'@/views/pages/my-rewards/MyRewards.vue')
                        },
                    ]
                },
                {
                    path: 'audio-web/:mode/:id',
                    name: 'NewsReader',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'NewsReader',
                            component: () => import(/* webpackChunkName: "NewsReader" */'@/views/pages/news-reader/index.vue')
                        },
                    ]
                },
                {
                    path: 'auto-dubbing/:mode/:id',
                    name: 'AutoDubbing',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'AutoDubbing',
                            component: () => import(/* webpackChunkName: "AutoDubbing" */'@/views/pages/auto-dubbing/index.vue')
                        },
                    ]
                },

            ]
        },
        {
            path: '/text-to-video/loading',
            name: 'Ttv-Loading',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "text-to-video-loading" */'@/views/pages/generative-ai/text-to-video/loading.vue')
        },
        {
            path: '/subscriptions/success',
            name: 'Payment-Success',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "text-to-video-loading" */'@/views/pages/subscriptions/success/index.vue')
        },
        {
            path: '/menu',
            name: 'MobileMenu',
            component: MobileModal
        },
        {
            path: '/select-role',
            name: 'SelectRole',
            component: SelectRole
        },
        {
            path: '/select-role/choose',
            name: 'ChooseType',
            component: ChooseType
        },

        // {
        //     path: '/404-not-found',
        //     name: 'Page404',
        //     component: () => import('@/views/general/404.vue')
        // },
        {
            path: '/404-not-found',
            name: 'Page404',
            component: () => import('@/views/general/404V2.vue')
        },
        {
            path: '/mobile-not-found',
            name: 'MobileNotFound',
            component: () => import('@/views/general/mobileNotReady.vue')
        },
        {
            path: '/we-are-sorry',
            name: 'WeAreSorry',
            component: () => import('@/views/general/weAreSorry.vue')
        },
        {
            path: "/",
            name: "UserProject",
            props: true,
            // beforeEnter: () => {
            //     window.location.href = base_url_user_project;
            // }
        },
        {
            path: '/top-up',
            name: 'TopUp',
            beforeEnter : AuthRequired,
            component : () => import('@/views/pages/topup-swc/index.vue')
        },
        {
            path: '/top-up/payment',
            name: 'Payment',
            beforeEnter : AuthRequired,
            component : () => import('@/views/pages/subscriptions/payment.vue')
        },
        {
            path: '/top-up/swc-payment',
            name: 'Swc-Payment',
            beforeEnter : AuthRequired,
            component : () => import('@/views/pages/subscriptions/swc/index.vue')
        },
        {
            path: '/create-pin',
            name: 'Create-Pin',
            beforeEnter : AuthRequired,
            component : () => import('@/views/pages/create-pin/index.vue')
        },
        {
            path: '/text-to-quiz/:mode/:id',
            name: 'Generative-Ai',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "multiple-choice" */'@/views/pages/generative-ai/text-to-quiz/NewUI2.vue')
        },
        {
            path: '/text-to-video',
            name: 'Generative-Ai-Text-To-Video',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "multiple-choice" */'@/views/pages/generative-ai/text-to-video')
        },
        {
            path: '/text-to-video/record-voice',
            name: 'TTV-Record-Voice',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "multiple-choice" */'@/views/pages/generative-ai/text-to-video/RecordVoice')
        },
        {
            path: '/voice-studio/:mode/:id',
            name: 'Generate-Videos',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "multiple-choice" */'@/views/pages/generate-videos')
        },
        {
            path: '/community/create',
            name: 'Create-Community',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "create-community" */'@/views/pages/community/CreateCommunity.vue')
        },
        {
            path: '/community/new-member-confirmations',
            name: 'Community-New-Member-Confirmations',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "create-community" */'@/views/pages/community/NewMemberConfirmations.vue')
        },
        {
            path: '/community/decrease-members',
            name: 'Decrease-Member',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "decrease-member" */'@/views/pages/community/DecreaseMember.vue')
        },
        {
            path: '/soca-certified-creator',
            name: 'Soca-Certified-Creator',
            beforeEnter: AuthRequired,
            component: () => import(/* webpackChunkName: "Soca-Certified-Creator" */'@/views/pages/soca-certified-creator/index.vue')
        },
        {
            path: '/mobile-maintenance',
            name: 'Mobile-Maintenance',
            component: () => import(/* webpackChunkName: "Mobile-Maintenance" */'@/views/pages/maintenance/Mobile.vue')
        },
        {
            path: '/auto-dubbing-maintenance',
            name: 'AD-Maintenance',
            component: () => import(/* webpackChunkName: "AD-Maintenance" */'@/views/pages/auto-dubbing/Maintenance.vue')
        },
        {
            path: '/:catchAll(.*)', redirect: '404-not-found'
        }

    ],
    base: '/'
})

export default router
