
var base_url = "https://stg-api-admin.soca.ai"

var base_url_general = "https://stg-api-landingpage.soca.ai/"

var base_url_bossman = "https://stg-api-bossman.soca.ai/"

var base_url_socket = "https://stg-socket-service.soca.ai"

var base_url_home = "https://stg-creator.soca.ai/"

var base_url_machine_learning = "https://stg-ml.soca.ai/"

var base_url_auto_dubbing = "https://stg-dubbing.soca.ai/"

var base_url_unsplash = "https://api.unsplash.com/"

var base_url_landing_page = "https://stg-api-landingpage.soca.ai/"

var base_url_asset = "https://stg-api-asset.soca.ai/"

var base_url_mobile_creator = "https://stg-m-creator.soca.ai/"

var base_url_notifications = "https://stg-api-notifications.soca.ai/"

var base_url_rewards = "https://stg-api-rewards.soca.ai/"

var base_url_qgen = "https://stg-q-gen.soca.ai/"

var base_url_pexels = "https://api.pexels.com/"

var base_url_api_public = "https://stg-api-public.soca.ai/"

var base_url_docs = "https://stg-docs-api.soca.ai/"

var base_url_api_referral = "https://stg-api-referral.soca.ai/"

var base_url_referral = "https://stg-dash-referral.soca.ai"


if (process.env.VUE_APP_MODE === "stg") {
    base_url = "https://stg-api-admin.soca.ai"

    base_url_general = "https://stg-api-landingpage.soca.ai/"

    base_url_bossman = "https://stg-api-bossman.soca.ai/"

    base_url_socket = "https://stg-socket-service.soca.ai"

    base_url_home = "https://stg-creator.soca.ai/"

    base_url_machine_learning = "https://stg-ml.soca.ai/"

    base_url_auto_dubbing = "https://stg-dubbing.soca.ai/"

    base_url_unsplash = "https://api.unsplash.com/"

    base_url_landing_page = "https://stg-api-landingpage.soca.ai/"

    base_url_asset = "https://stg-api-asset.soca.ai/"

    base_url_mobile_creator = "https://stg-m-creator.soca.ai/"

    base_url_notifications = "https://stg-api-notifications.soca.ai/"

    base_url_rewards = "https://stg-api-rewards.soca.ai/"

    base_url_qgen = "https://stg-q-gen.soca.ai/"

    base_url_pexels = "https://api.pexels.com/"

    base_url_api_public = "https://stg-api-public.soca.ai/"

    base_url_docs = "https://stg-docs-api.soca.ai/"

    base_url_api_referral = "https://stg-api-referral.soca.ai/"

    base_url_referral = "https://stg-dash-referral.soca.ai"
    
} else if (process.env.VUE_APP_MODE === "prod") {
    base_url = "https://api-admin.soca.ai"

    base_url_general = "https://api-landingpage.soca.ai/"

    base_url_bossman = "https://api-bossman.soca.ai/"

    base_url_socket = "https://socket-service.soca.ai"

    base_url_home = "https://app.soca.ai/"

    base_url_machine_learning = "https://ml.soca.ai/"

    base_url_auto_dubbing = "https://dubbing.soca.ai/"

    base_url_unsplash = "https://api.unsplash.com/"

    base_url_landing_page = "https://api-landingpage.soca.ai/"

    base_url_asset = "https://api-asset.soca.ai/"

    base_url_mobile_creator = "https://m-creator.soca.ai/"

    base_url_notifications = "https://api-notifications.soca.ai/"

    base_url_rewards = "https://api-rewards.soca.ai/"

    base_url_qgen = "https://q-gen.soca.ai/"

    base_url_pexels = "https://api.pexels.com/"

    base_url_api_public = "https://api-public.soca.ai/"

    base_url_docs = "https://docs-api.soca.ai/"

    base_url_api_referral = "https://api-referral.soca.ai/"

    base_url_referral = "https://dash-referral.soca.ai"
    
} else if (process.env.VUE_APP_MODE === "dev") {
    base_url = "https://stg-api-admin.soca.ai"

    base_url_general = "https://stg-api-landingpage.soca.ai/"

    base_url_bossman = "https://stg-api-bossman.soca.ai/"

    base_url_socket = "https://stg-socket-service.soca.ai"

    base_url_home = "https://stg-creator.soca.ai/"

    base_url_machine_learning = "https://stg-ml.soca.ai/"

    base_url_auto_dubbing = "https://stg-dubbing.soca.ai/"

    base_url_unsplash = "https://api.unsplash.com/"

    base_url_landing_page = "https://stg-api-landingpage.soca.ai/"

    base_url_asset = "https://stg-api-asset.soca.ai/"

    base_url_mobile_creator = "https://stg-m-creator.soca.ai/"

    base_url_notifications = "https://stg-api-notifications.soca.ai/"

    base_url_rewards = "https://stg-api-rewards.soca.ai/"

    base_url_qgen = "https://stg-q-gen.soca.ai/"

    base_url_pexels = "https://api.pexels.com/"

    base_url_api_public = "https://stg-api-public.soca.ai/"

    base_url_docs = "https://stg-docs-api.soca.ai/"

    base_url_api_referral = "https://stg-api-referral.soca.ai/"

    base_url_referral = "https://stg-dash-referral.soca.ai"
}

export {
    base_url,
    base_url_general,
    base_url_bossman,
    base_url_socket,
    base_url_home,
    base_url_machine_learning,
    base_url_auto_dubbing,
    base_url_unsplash,
    base_url_landing_page,
    base_url_asset,
    base_url_mobile_creator,
    base_url_notifications,
    base_url_rewards,
    base_url_qgen,
    base_url_pexels,
    base_url_api_public,
    base_url_docs,
    base_url_api_referral,
    base_url_referral
}