export default {
    state: {
        author: localStorage.getItem('author_quiz') != null ? JSON.parse(localStorage.getItem('author_quiz')) : null,
    },
    getters: {
        author: state => state.author,
    },
    mutations: {
        setAuthor(state, payload) {
            state.author = payload
            localStorage.setItem("author_quiz", payload)
        },
    },
    actions: {
        setAuthor({ commit }, payload) {
            console.log("ini payload", payload)
            commit('setAuthor', payload)
        }
    }

}