<template>
    <div class="h-100">
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-12 d-flex flex-column align-items-center justify-content-between text-white">
                    <div class="container d-flex align-items-center pl-3 pl-md-4 pt-3 text-white cursor-pointer">
                    </div>
                    <div class="new-pass-card">
                        <div class="card-body">
                        <h2 class="mt-4 mb-1 text-dark" style="font-size:26px;">{{ $t('new-password') }}</h2>
                        <p class="mt-3 text-dark" style="font-size:16px;">{{ $t('create-anew-password') }}</p>
                        <div class="mx-md-4 my-3">
                            <div class="form-group text-left">
                                    <label for="txt-password">{{ $t('new-password') }}</label>
                                    <div class="position-relative d-flex align-items-center">
                                        <input :type="typeInput" class="form-control" id="txt-password" :placeholder="$t('new-password-placeholder')" v-model="password" @input="checkPasswordStrength">
                                        <div class="append position-absolute cursor-pointer" @click="showPassword">
                                            <img :src="require(`@/assets/images/icons/${ic_eye}`)" alt="Show Password" class="ic-eye">
                                        </div>
                                    </div>
                                    <div v-if="password" class="mt-3">
                                        <div v-for="(data, index) in secure_password" :key="index" class="d-flex align-items-center">
                                            <img v-if="data.status" src="@/assets/images/icons/ic_check.svg" alt="">
                                            <img v-else src="@/assets/images/icons/ic_cross.svg" alt="">
                                            <span class="pl-2 text-dark">{{ $t(data.condition) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <form @submit.prevent="postNewPassword" class="text-left">
                                    <div class="form-group text-left position-relative mb-1">
                                        <label for="txt-confirm-password">{{ $t('confirm-password') }}</label>
                                        <div class="position-relative d-flex align-items-center">
                                            <input :type="typeInputConfirm" class="form-control" id="txt-confirm-password" :placeholder="$t('confirm-password-placeholder')" v-model="passwordConfirm" :style="!isPasswordTheSame ? 'border: 2px solid #EB5757;' : ''">
                                            <div class="append position-absolute cursor-pointer" @click="showPasswordConfirm">
                                                <img :src="require(`@/assets/images/icons/${ic_eye_confirm}`)" alt="Show Password" class="ic-eye">
                                            </div>
                                        </div>
                                    </div>
                                    <span class="text-alert text-left mb-2" v-if="!isPasswordTheSame">
                                        {{ $t('password-do-not-match') }}
                                    </span>
                                    <button v-if="!is_password_secure" class="btn btn-disabled btn-md btn-block mt-3" >
                                        <h5 class="my-1 text-white">{{ $t('save') }}</h5>
                                    </button>
                                    <button v-else class="btn btn-dark btn-md btn-block mt-3">
                                        <h5 v-if="loading" class="my-1 text-white">Loading...</h5>
                                        <h5 v-else class="my-1 text-white">Save</h5>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="copyright text-white mb-5">
                        <small style="color:#A6A6A6;">Copyright &copy; 2024 Soca.ai All Reserved</small>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { newPassword } from "@/services/new-password/new_password.service";
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
    mixins: [AlertUtils],
    data() {
        return {
            password: null,
            passwordConfirm: null,
            isPasswordTheSame: true,
            loading: false,
            typeInput: 'password',
            typeInputConfirm: 'password',
            status_show_password : false,
            status_show_password_confirm : false,
            ic_eye : 'ic_eye_dark.svg',
            ic_eye_confirm : 'ic_eye_dark.svg',
            is_password_secure : false,
            secure_password : [
                {
                    condition : 'validation-onelowercaseletter',
                    status : false
                },
                {
                    condition : 'validation-oneuppercaseletter',
                    status : false
                },
                {
                    condition : 'validation-onedigit',
                    status : false
                },
                {
                    condition : 'validation-minimumof8characters',
                    status : false
                },
            ],
        }
    },

    methods:{
        postNewPassword(){
            if(this.is_password_secure){
                if(this.password === this.passwordConfirm){
                    this.loading = true;
                    this.isPasswordTheSame = true;

                    let formData = {
                        newPassword: this.password,
                        confirmPassword: this.passwordConfirm
                    }
                    newPassword(formData)
                    .then((response) => {
                        if(response.status == true) {
                            this.alertSuccess('Your password has been changed.')
                            localStorage.removeItem("token_client")
                            if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                                this.$router.push({ name : 'SignIn', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                                this.$router.push({ name : 'SignIn', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                                this.$router.push({ name : 'SignIn', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                            } else {
                                this.$router.push({ name : 'SignIn' })
                            }
                            this.loading = false;
                        } else {
                            console.log(response)
                            this.loading = false;
                        }
                    })
                } else {
                    this.isPasswordTheSame = false;
                }
            }
        },

        showPassword(){
            if(this.status_show_password){
                this.status_show_password = false
                this.ic_eye = 'ic_eye_dark.svg'
                this.typeInput = 'password'
            } else {
                this.status_show_password = true
                this.ic_eye = 'ic_eye_slash_dark.svg'
                this.typeInput = 'text'
            }
        },

        showPasswordConfirm(){
            if(this.status_show_password_confirm){
                this.status_show_password_confirm = false
                this.ic_eye_confirm = 'ic_eye_dark.svg'
                this.typeInputConfirm = 'password'
            } else {
                this.status_show_password_confirm = true
                this.ic_eye_confirm = 'ic_eye_slash_dark.svg'
                this.typeInputConfirm = 'text'
            }
        },

        checkPasswordStrength() {
            const hasLowerCase = /[a-z]/.test(this.password);
            const hasUpperCase = /[A-Z]/.test(this.password);
            const hasNumber = /\d/.test(this.password);
            const hasMinimumLength = this.password.length >= 8;

            this.secure_password.forEach((condition, index) => {
                switch (index) {
                    case 0:
                        condition.status = hasLowerCase;
                        break;
                    case 1:
                        condition.status = hasUpperCase;
                        break;
                    case 2:
                        condition.status = hasNumber;
                        break;
                    case 3:
                        condition.status = hasMinimumLength;
                        break;
                    default:
                        break;
                }
            });

            if(hasLowerCase && hasUpperCase && hasNumber && hasMinimumLength) {this.is_password_secure = true} else {this.is_password_secure = false}
        }
    }
}
</script>

<style scoped>
.wrapper {
    height: 100vh;
    background-color : black;
}
label{
    font-size:16px;
    color: #000 !important;
}

.new-pass-card{
    width: 475px
}

.verif-card{
    width: 475px
}

.card__banner{
    background: linear-gradient(223deg, #D9BEBE 0%, #A69DE5 100%);
}

.soca-logo {
    width: 125px;
}
.content__banner{
    padding-top: 3rem;
    padding-left: 3rem;
}
.forgot__container{
    width: 475px;
}
.auth__banner{
    width: 96%;
    position: absolute;
    bottom: 0;
}
.card{
    border-radius: 0;
    background-color: #121418 !important;
}
.img__auth{
    width: 79%;
    height: 100% !important;
    border-left: solid 3px transparent;
    border-top: solid 3px transparent;
    border-top-left-radius: 20px;
    border-color: #ffffff;
    /* background-image: linear-gradient(white, white), linear-gradient(135deg, #473FF4 0%, #E52C96 100%);
    background-origin: border-box;
    background-clip: content-box, border-box; */
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
    .banner_desc {
        font-size: 18px !important;
        width: 92% !important;
    }

    .title__banner{
        font-size: 62px !important;
        line-height: 44px !important;
    }

    .img__auth{
        width: 94% !important;
    }

    .content__banner {
        padding-top: 5rem !important;
        padding-left: 3rem !important;
    }
}

@media only screen and (min-height: 795px) and (max-height: 910px){
    .content__banner {
        padding-top: 4.5rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-height: 765px) and (max-height: 790px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1370px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 86% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 15px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 50px !important;
    }
}
 .title__banner{
    font-size: 52px;
    color: #000000;
    line-height: 39px;
    font-family: 'IBM Plex Serif';
}

.banner_desc{
    color: #000000;
    font-size: 18px;
    width: 74%;
}

.form-control{
    background-color: transparent;
    color: #000 !important;
    border-color: #383838;
    padding-right: 35px;
}

.btn-dark:disabled h5{
    color: #8C8C8C !important;
}
.btn-dark{
    border-radius: 6px;
    background-color: #000;
    border: 1px solid #000;
    color: white;
}

.copyright{
    left: 0;
    right: 0;
    bottom: 0px;
}

.wrapper .container-fluid{
    height: 90vh;
}

input:focus {
    color: white;
    background-color: transparent;
}

.append {
    right: 0;
    padding-right: 10px;
}

.btn-disabled {
    background-color: #2D2D2D;
    border: 1px solid #2D2D2D;
    color: white;
    box-shadow: none !important;
}

.text-alert{
    font-size: 12px !important;
    color: #EB5757;
}

@media only screen and (max-width: 860px) {
    .new-pass-card{
        width: 100%;
        background-color: transparent !important;
    }

    .soca-logo {
        width: 8rem;
    }
}
</style>