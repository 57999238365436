
import { signIn } from '../../services/sign-in/signin.service'
export default {
    state: {
        users: localStorage.getItem('users') != null ? JSON.parse(localStorage.getItem('users')) : null,
        processing: false,
        loginError: null,
    },
    getters: {
        users: state => state.users,
        processing: state => state.processing,
        loginError: state => state.loginError,
    },
    mutations: {
        setUsers(state, payload) {
            state.users = payload,
            state.loginError = null
            state.processing = false
        },
        setError(state, payload) {
            state.loginError = payload
            state.users = null
            state.processing = false
        },
        setLogout(state) {
            state.users = null,
            state.loginError = null,
            state.processing = false
        },
        setProcessing(state, payload) {
            state.processing = payload
        },
    },
    actions: {
        setUsers({ commit }, payload) {
            commit('setUsers', payload)
        },
        login({ commit }, payload) {
            commit('setProcessing', true)
            var formData = {
                email : payload.email,
                password : payload.password
            }

            signIn(formData)
            .then((response) => {
                if(response.status == true){
                    localStorage.setItem("users", JSON.stringify(response.data))
                    commit('setUsers', response.data)
                } else {
                    localStorage.removeItem('users')
                    commit('setError', response)
                }
            })
        },
        processingFalse({commit}, payload){
            commit('setProcessing', payload)
        },
        logout({ commit }){
            localStorage.clear()
            sessionStorage.clear()
            commit('setLogout')
        }
    }

}