<template>
    <div>
        <b-modal id="email-tidak-terdaftar-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('email-tidak-terdaftar-modal'), $router.replace({ params: {} })"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_wrong.svg" alt="Login Failed">
                <div class="mt-4 text-white">
                    <h4 class="font-weight-bold mb-4">{{ $t('user-not-found') }}</h4>
                    <h5 class="font-weight-normal" v-html="$t('user-not-found-p')"></h5>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">
                    <button class="btn btn-grey mr-1" @click="$bvModal.hide('email-tidak-terdaftar-modal'), $router.replace({ params: {} })"><p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('not-now') }}</p></button>
                    <button class="btn btn-purple ml-1" @click="toSignUp"><p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('sign-up--text') }}</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  methods : {
    toSignUp(){
        if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
            this.$router.push({ name : 'SignUp', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
        } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
            this.$router.push({ name : 'SignUp', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
        } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
            this.$router.push({ name : 'SignUp', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
        } else {
            this.$router.push({ name : 'SignUp' })
        }
    },
  }
}
</script>

<style scoped>
.btn-purple {
  background-color: #6d75f6;
  border: 1px solid #6d75f6;
  color: white;
  box-shadow: none !important;
}

.btn-grey {
  background-color: #2D2D2D;
  border: 1px solid #2D2D2D;
  color: white;
  box-shadow: none !important;
}
</style>
<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#email-tidak-terdaftar-modal .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#email-tidak-terdaftar-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#email-tidak-terdaftar-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

button {
  box-shadow: none !important;
}
</style>