// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enMessages from './locales/en.json';
import idMessages from './locales/id.json';

Vue.use(VueI18n);

const messages = {
  en: enMessages,
  id: idMessages,
};

const savedLocale = localStorage.getItem('locale');

const i18n = new VueI18n({
  locale: savedLocale || 'en',
  messages,
});

// Fungsi untuk menentukan bahasa berdasarkan geolokasi
function getLocaleFromGeolocation() {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude } = position.coords;


        const isIndonesia = latitude >= -7 && latitude <= 6;
        console.log(isIndonesia, latitude)

        if (!savedLocale) {
          i18n.locale = isIndonesia ? 'id' : 'en';
          localStorage.setItem('locale', i18n.locale);
        }

        resolve(i18n.locale);
      },
      error => {
        console.error('Error getting geolocation:', error);

        if (!savedLocale) {
          i18n.locale = 'en';
          localStorage.setItem('locale', 'en');
        }

        resolve(i18n.locale);
      }
    );
  });
}

// Fungsi untuk menentukan bahasa berdasarkan alamat IP
// function getLocaleFromIP() {
//   return new Promise((resolve) => {
//     // Ganti URL dengan endpoint yang sesuai dari layanan ipinfo.io
//     const ipinfoEndpoint = 'https://ipinfo.io/json?token=d33bc7800fe107';

//     // Lakukan permintaan HTTP untuk mendapatkan informasi lokasi berdasarkan IP
//     fetch(ipinfoEndpoint)
//       .then(response => response.json())
//       .then(data => {
//         // Dapatkan informasi negara dari respons
//         const country = data.country;

//         // Tentukan bahasa berdasarkan negara (contoh: jika negara Indonesia, gunakan bahasa Indonesia)
//         const isIndonesia = country === 'ID';

//         if (!savedLocale) {
//           i18n.locale = isIndonesia ? 'id' : 'en';
//           localStorage.setItem('locale', i18n.locale);
//         }

//         resolve(i18n.locale);
//       })
//       .catch(error => {
//         console.error('Error getting IP-based location:', error);

//         if (!savedLocale) {
//           i18n.locale = 'en';
//           localStorage.setItem('locale', 'en');
//         }

//         resolve(i18n.locale);
//       });
//   });
// }
// Panggil fungsi ini hanya jika locale belum ada di localStorage
if (!savedLocale) {
  // getLocaleFromIP();
  getLocaleFromGeolocation()
}

export default i18n;
