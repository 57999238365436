<template>
    <div class="h-100 mt-4">
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-12 d-flex flex-column align-items-center justify-content-between forgot-password-card text-white">
                    <div></div>
                    <div class="forgot__container">
                        <div class="card-body">
                        <h2 class="mt-4 mb-1 text-dark" style="font-size:26px; color: #000 !important;">{{ $t('forgot-password-head') }}</h2>

                        <div class="mx-md-4 my-3">
                            <form @submit.prevent="postForgotPassword">
                                <div class="form-group text-left">
                                    <label for="txt-email">Email</label>
                                    <input type="email" class="form-control" id="txt-email" :placeholder="$t('email--placeholder')" v-model="email">
                                </div>
                                <button class="btn btn-dark btn-md btn-block mt-3">
                                    <h5 v-if="loading" class="my-1 text-white">Loading...</h5>
                                    <h5 v-else class="my-1 text-white">{{ $t('submit')  }}</h5>
                                </button>
                            </form>
                        </div>
                    </div>
                    </div>
                    <div class="copyright text-white mb-5">
                        <small style="color:#A6A6A6;">Copyright &copy; 2024 Soca.ai All Reserved</small>
                    </div>
                </div>
            </div>
        </div>
        <EmailTidakTerdaftar/>
        <OtpSended />
    </div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
import { forgotPassword } from "@/services/forgot-password/forgot_password.service";
import EmailTidakTerdaftar from '@/components/modal/EmailTidakTerdaftar'
import OtpSended from '@/components/modal/OtpSended'

export default {
    mixins: [AlertUtils],
    components:{
        EmailTidakTerdaftar,
        OtpSended
    },
    data() {
        return {
            email: null,
            loading: false,
        }
    },

    methods:{
        postForgotPassword(){
            this.loading = true;

            let formData = {
                email : this.email
            }
            forgotPassword(formData)
            .then((response) =>{
                if(response.status == true){
                    this.loading = false;
                    localStorage.setItem("token_client", response.data)
                    this.alertSuccess(this.$t('otp-sent-email'))
                    if(this.$route.name == 'Forgot-Password') {
                        if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                            this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                        } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                            this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                        } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                            this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                        } else {
                            this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password'} })
                        }
                    } else {
                        if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                            this.$router.push({ name : 'Verifikasi', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                        } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                            this.$router.push({ name : 'Verifikasi', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                        } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                            this.$router.push({ name : 'Verifikasi', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                        } else {
                            this.$router.push({ name : 'Verifikasi' })
                        }
                    }
                    // this.$bvModal.show('otp-terkirim')
                } else {
                    this.$bvModal.show('email-tidak-terdaftar-modal')
                    this.loading = false;
                }
            })
        }
    }
}
</script>

<style scoped>

label{
    color: #000 !important;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.soca-logo {
    width: 125px;
}
.content__banner{
    padding-top: 3rem;
    padding-left: 3rem;
}
.forgot__container{
    width: 475px;
}
.auth__banner{
    width: 96%;
    position: absolute;
    bottom: 0;
}
.card{
    border-radius: 0;
    background-color: #121418 !important;
}
.img__auth{
    width: 79%;
    height: 100% !important;
    border-left: solid 3px transparent;
    border-top: solid 3px transparent;
    border-top-left-radius: 20px;
    border-color: #ffffff;
    /* background-image: linear-gradient(white, white), linear-gradient(135deg, #473FF4 0%, #E52C96 100%);
    background-origin: border-box;
    background-clip: content-box, border-box; */
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
    .banner_desc {
        font-size: 18px !important;
        width: 92% !important;
    }

    .title__banner{
        font-size: 62px !important;
        line-height: 44px !important;
    }

    .img__auth{
        width: 94% !important;
    }

    .content__banner {
        padding-top: 5rem !important;
        padding-left: 3rem !important;
    }
}

@media only screen and (min-height: 795px) and (max-height: 905px){
    .content__banner {
        padding-top: 4.5rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-height: 765px) and (max-height: 790px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1370px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 86% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 15px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 50px !important;
    }
}
 .title__banner{
    font-size: 52px;
    color: #000000;
    line-height: 39px;
    font-family: 'IBM Plex Serif';
}

.banner_desc{
    color: #000000;
    font-size: 18px;
    width: 74%;
}

.forgot-password-card{
    width: 475px
}

.form-control::placeholder{
    color: #8C8C8C;
    font-family: "Avenir Next";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.form-control{
    background-color: transparent;
    color: #000 !important;
    border-color: #383838;
}

input:focus {
    color: white;
    background-color: transparent;
}

.btn-dark:disabled h5{
    color: #8C8C8C !important;
}
.btn-dark{
    color: white;
    min-height: 45px;
    border-radius: 6px;
    background-color: #000;
    border: 1px solid #000
}

.copyright{
    left: 0;
    right: 0;
    bottom: 0px;
}

.wrapper .container-fluid{
    height: 90vh;
}

@media only screen and (max-width: 860px) {
    .forgot-password-card{
        background-color: transparent !important;
        width: 100%;
    }

    .soca-logo {
        width: 8rem;
    }
}
</style>