import axios from "../../config/axios";
import { base_url_landing_page }  from "../../config/base_url";

export async function checkStatusPin() {

    return axios.get(base_url_landing_page + 'api/pin-status')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function setPin(payload) {
	return axios.post(base_url_landing_page + 'api/set-pin', payload)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function confirmPinOtp(payload) {
	return axios.post(base_url_landing_page + 'api/confirm-otp-pin', payload)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function requestSetPin() {
	return axios.get(base_url_landing_page + 'api/request-set-pin')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function confirmPin(payload) {
	return axios.post(base_url_landing_page + 'api/confirm-pin', payload)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}