<template>
<div class="h-100 position-relative" style="height:100vh">
    <div class="wrapper__inner container-fluid h-100 text-white">
        <div class="row h-100">
            <div class="col-12 align-items-center d-flex justify-content-center">
                <div style="height:99vh; overflow-y: auto;" class="w-100 d-flex flex-column align-items-center justify-content-center">

                    <div class="sign-up-card" :class="{ 'm-auto' : password }">
                        <div class="card-body text-center">
                            <h4 class="mt-3 mb-1 text-dark" style="font-size:26px;">{{ $t('create-an-account') }}</h4>
                            <div class="login-session mx-md-5 py-3 text-center">
                                <!-- SEAMLESS SIGN IN -->
                                <!-- <button class="btn btn-dark btn-black btn-block btn-md text-white d-flex align-items-center justify-content-center"><img src="@/assets/images/icons/ic_apple.svg" class="mr-3" alt="ios"><h5 class="">Signup with Apple</h5></button> -->
                                <button class="btn btn-white btn-block btn-md d-flex align-items-center justify-content-center mt-3" @click="newOauth"><img src="@/assets/landing-page/google.svg" class="mr-2 img-fluid google-logo" alt="ios"><h5 class="" style="font-size:16px;">{{ $t('sign-up-with') }}</h5></button>

                                <p class="mb-0 mt-2 text-dark">{{ $t('or') }}</p>
 
                                <!-- FORM SIGNIN -->
                                <form @submit.prevent="signUp">
                                    <div class="form-group text-left">
                                        <label for="txt-email">Email</label>
                                        <input type="email" class="form-control" :placeholder="$t('email--placeholder')" id="txt-email" v-model="email" required autocomplete="off">
                                        <div class="reminder--email" :class="{'show mt-1': invalid_email, 'show--shake': email_is_still_invalid}">
                                            <p class="text-danger" style="font-size: 12px;">Invalid email format!</p>
                                        </div>
                                    </div>
                                    <div class="form-group text-left">
                                        <label for="txt-phone">{{ $t('phone-number') }}</label>
                                        <input type="number" class="form-control" :placeholder="$t('phone--placeholder')" id="txt-phone" v-model="phone" required autocomplete="off">
                                        <!-- <div class="reminder--phone" :class="{'mt-3  show': phone}">
                                            <div v-for="(data, index) in secure_phone" :key="index" class="d-flex align-items-center">
                                                <img v-if="data.status" src="@/assets/images/icons/ic_check.svg" alt="">
                                                <img v-else src="@/assets/images/icons/ic_cross.svg" alt="">
                                                <span class="pl-2 text-dark">{{ $t(data.condition) }}</span>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="form-group text-left">
                                        <label for="txt-password">{{ $t('password') }}</label>
                                        <div class="position-relative d-flex align-items-center">
                                            <input :type="typeInput" class="form-control" :placeholder="$t('password--placeholder')" id="txt-password" v-model="password" required autocomplete="off" @input="checkPasswordStrength" style="padding-right:35px">
                                            <div class="append position-absolute cursor-pointer" @click="showPassword">
                                                <img :src="require(`@/assets/images/icons/${ic_eye}`)" alt="Show Password" class="ic-eye">
                                            </div>
                                        </div>
                                        <div class="mt-3 reminder--pasword" :class="{'show': password}">
                                            <div v-for="(data, index) in secure_password" :key="index" class="d-flex align-items-center">
                                                <img v-if="data.status" src="@/assets/images/icons/ic_check.svg" alt="">
                                                <img v-else src="@/assets/images/icons/ic_cross.svg" alt="">
                                                <span class="pl-2 text-dark">{{ $t(data.condition) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-dark btn-md btn-block mt-3" :disabled="!is_password_secure">
                                        <!-- <div v-if="loading" class="spinner-border text-light" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div> -->
                                        <h5 v-if="loading" class="text-white" style="font-size:16px;">Loading...</h5>
                                        <h5 v-else class="text-white" style="font-size:16px;">{{ $t('sign-up--text') }}</h5>
                                    </button>
                                </form>
                            </div>
                            <p class="mb-5 mb-md-0 text-dark text-nowrap text-center" style="font-size:14px;">{{ $t('privacy-policy') }} <br><span style="color:#6D75F6; cursor:pointer" @click="$router.push({path: '/terms-conditions'})">{{ $t('privacy-policy--ts') }}</span></p>
                            <p class="mb-0 mt-2 text-dark" style="font-size:14px;">{{ $t('already-have-account') }} <span class="cursor-pointer" @click="toSignIn()" style="color:#6D75F6;">{{ $t('sign-in') }}</span></p>
                        </div>
                    </div>
                    <div class="copyright text-white mt-3">
                        <small style="color:#A6A6A6;">Copyright &copy; 2024 Soca.ai All Reserved</small>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <AkunSudahTerdaftar />
    <EmailBerhasilDaftar />
    <OtpSended />
</div>
</template>

<script>
// import router from '@/router';
// import Swal from 'sweetalert2';
import axios from "axios"
import { registerUser } from "@/services/projects/projects.service";
import { AlertUtils } from "@/mixins/AlertUtils";
import { signUpUser } from "@/services/register/register.service";
import { base_url_general } from "@/config/base_url"
import AkunSudahTerdaftar from '@/components/modal/AkunSudahTerdaftar'
import EmailBerhasilDaftar from '@/components/modal/EmailBerhasilDaftar'
import OtpSended from '@/components/modal/OtpSended'
import { mapGetters } from "vuex";
import { getUser } from '@/services/user/user.service'


// import { getPlay } from '@/services/quiz/quiz.service'
// import { subscriptions } from '@/services/subscriptions/subscriptions.service'

export default {
    name: "sign-up",
    mixins: [AlertUtils],
    components: {
        AkunSudahTerdaftar,
        EmailBerhasilDaftar,
        OtpSended
    },
    data() {
        return {
            viewportWidth: window.innerWidth,
            email: null,
            password: null,
            phone: null,
            loading: false,
            typeInput: 'password',
            status_show_password : false,
            ic_eye : 'ic_eye_dark.svg',
            is_password_secure : false,
            is_phone_secure : false,
                secure_phone : [
                    {
                        condition : 'phone-validation-1',
                        status : false
                    },
                    {
                        condition : 'phone-validation-2',
                        status : false
                    },
            ],
            secure_password : [
                {
                    condition : 'validation-onelowercaseletter',
                    status : false
                },
                {
                    condition : 'validation-oneuppercaseletter',
                    status : false
                },
                {
                    condition : 'validation-onedigit',
                    status : false
                },
                {
                    condition : 'validation-minimumof8characters',
                    status : false
                },
            ],
            invalid_email: false,
            email_is_still_invalid: false,
        }
    },
    mounted(){
        this.axiosInstance = axios.create({
            headers : ""
        })

        if(this.users != null){
            this.checkRequestLogin()
            // this.$router.push({ name: 'Home'})
        }

        if(this.$route.query.token != undefined) {
            localStorage.setItem("token_client", this.$route.query.token)
            this.getUser()
        } else if(this.$route.query.failed == 'user-not-found') {
            this.$bvModal.show('email-tidak-terdaftar-modal')
            localStorage.removeItem("token_client")
        } else {
            localStorage.removeItem("token_client")
        }
    },
    computed:{
        ...mapGetters(["users", "processing", "loginError"])
    },
    methods: {
        checkRequestLogin(){
            this.$router.push({ name : 'Home' })
        },

        getUser() {
            this.loading = true;
            getUser()
            .then((response) => {
                if(response.status) {
                    localStorage.setItem("users", JSON.stringify(response.data))
                    this.checkRequestLogin()
                    this.loading = false;
                } else {
                    localStorage.removeItem("token_client")
                    this.loading = false;
                }
            })
            .catch((err) => {
                console.log(err)
                this.loading = false;
            })
        },

        signInWithGoogle() {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                    this.axiosInstance.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`
                        }
                    })
                    .then((res) => {
                        this.registerUser(res)
                    })
                },
            });

            client.requestAccessToken();
        },

        newOauth() {
            if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                window.location.href = base_url_general + 'auth/google?request=play&idQuiz=' + this.$route.query.idQuiz + '&category=' + this.$route.query.category + '&author=' + this.$route.query.author + '&platform=soca';
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                window.location.href = base_url_general + 'auth/google?request=use-template&idTemp=' + this.$route.query.idTemp + '&author=' + this.$route.query.author + '&platform=soca';
            } else if(this.$route.query.ref) {
                window.location.href = base_url_general + 'auth/google?platform=soca&code_referral=' + this.$route.query.ref
            } else {
                window.location.href = base_url_general + 'auth/google?platform=soca'
            }
        },

        registerUser(user_data) {
            var vm = this

            var dataPost = {
                name: user_data.data.name,
                email: user_data.data.email
            }

            new Promise(function(resolve) {
                setTimeout(() => resolve(registerUser(dataPost)))
            })
            .then(function(result) {
                if(!result.status) {
                    vm.$bvModal.show('akun-sudah-terdaftar-modal')
                } else {
                    localStorage.setItem("users", JSON.stringify(result.data))
                    vm.$bvModal.show('email-berhasil-terdaftar-modal')
                }
            })
        },

        signUp(){

            const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

            if(!emailRegex.test(this.email)){

                if(this.invalid_email){
                    this.email_is_still_invalid = true;
                    setTimeout(() => {
                        this.email_is_still_invalid = false;
                    }, 1000);
                }

                this.invalid_email = true;

                return;
            }

            this.invalid_email = false;

            if(this.is_password_secure && !this.invalid_email){
                let formData = {
                    email : this.email,
                    password : this.password,
                    phone_number : this.phone,
                    code_referral: this.$route.query.ref ? this.$route.query.ref : null
                }

                this.loading = true;

                localStorage.removeItem("token_client")

                var vm = this

                signUpUser(formData)
                .then((response) => {
                    if(response.status == true){
                        localStorage.setItem("token_client", response.data)
                        this.alertSuccess(this.$t('otp-sent-email'))
                        if(this.$route.name == 'Forgot-Password') {
                            if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                                this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                                this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                                this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password', request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                            } else {
                                this.$router.push({ name : 'Verifikasi', query : { key : 'forgot-password'} })
                            }
                        } else {
                            if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                                this.$router.push({ name : 'Verifikasi', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                                this.$router.push({ name : 'Verifikasi', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                                this.$router.push({ name : 'Verifikasi', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                            } else {
                                this.$router.push({ name : 'Verifikasi' })
                            }
                        }
                        this.loading = false;
                    } else {
                        vm.$bvModal.show('akun-sudah-terdaftar-modal')
                        this.loading = false;
                    }
                })
            }
        },

        showPassword(){
            if(this.status_show_password){
                this.status_show_password = false
                this.ic_eye = 'ic_eye_dark.svg'
                this.typeInput = 'password'
            } else {
                this.status_show_password = true
                this.ic_eye = 'ic_eye_slash_dark.svg'
                this.typeInput = 'text'
            }
        },

        toSignIn(){
            if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                this.$router.push({ name : 'SignIn', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                this.$router.push({ name : 'SignIn', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                this.$router.push({ name : 'SignIn', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
            } else {
                this.$router.push({ name : 'SignIn' })
            }
        },
        checkLengthPhone(){
                const hasMinimumLength = this.phone.length >= 7;
                const hasMaxLength = this.phone.length <= 30;
                this.secure_phone.forEach((condition, index) => {
                    switch (index) {
                        case 0:
                            condition.status = hasMinimumLength;
                            break;
                        case 1:
                            condition.status = hasMaxLength;
                            break;
                        default:
                            break;
                    }
                });

                if(hasMinimumLength && hasMaxLength) {this.is_phone_secure = true} else {this.is_phone_secure = false}
        },

        checkPasswordStrength() {
            const hasLowerCase = /[a-z]/.test(this.password);
            const hasUpperCase = /[A-Z]/.test(this.password);
            const hasNumber = /\d/.test(this.password);
            const hasMinimumLength = this.password.length >= 8;

            this.secure_password.forEach((condition, index) => {
                switch (index) {
                    case 0:
                        condition.status = hasLowerCase;
                        break;
                    case 1:
                        condition.status = hasUpperCase;
                        break;
                    case 2:
                        condition.status = hasNumber;
                        break;
                    case 3:
                        condition.status = hasMinimumLength;
                        break;
                    default:
                        break;
                }
            });

            if(hasLowerCase && hasUpperCase && hasNumber && hasMinimumLength) {this.is_password_secure = true} else {this.is_password_secure = false}
        },
    },
    watch: {
        
    }
 }
</script>

<style scoped>
.reminder--pasword, .reminder--phone, .reminder--email{
    height: 0px;
    opacity: 0;
    cursor: default;
    transition: all 0.5s;
}

.reminder--email.show--shake{
    animation: shakeSlow 1s infinite;
}
.reminder--email.show{
    height: 17px;
    opacity: 1;
    transition: all 0.5s;
}


@keyframes shakeSlow {
  0% {
      transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
}
  18.5% {
    transform: translateX(5px) rotateY(7deg);
}
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
}
  43.5% {
    transform: translateX(2px) rotateY(3deg);
}
  50% {
    transform: translateX(0);
}
}
.reminder--phone.show{
    height: 50px;
    opacity: 1;
    transition: all 0.5s;
}
.reminder--pasword.show{
    height: 90px;
    opacity: 1;
    transition: all 0.5s;
}
.card__banner{
    background: linear-gradient(223deg, #D9BEBE 0%, #A69DE5 100%);
}

.content__banner{
    padding-top: 3rem;
    padding-left: 3rem;
}
.auth__banner{
    width: 96%;
    position: absolute;
    bottom: 0;
}
.card{
    border-radius: 0;
    background-color: #121418 !important;
}
.img__auth{
    width: 79%;
    height: 100% !important;
    border-left: solid 3px transparent;
    border-top: solid 3px transparent;
    border-top-left-radius: 20px;
    border-color: #FFFFFF;
    /* background-image: linear-gradient(white, white), linear-gradient(135deg, #473FF4 0%, #E52C96 100%);
    background-origin: border-box;
    background-clip: content-box, border-box; */
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
    .banner_desc {
        font-size: 18px !important;
        width: 92% !important;
    }

    .title__banner{
        font-size: 62px !important;
        line-height: 44px !important;
    }

    .img__auth{
        width: 94% !important;
    }

    .content__banner {
        padding-top: 5rem !important;
        padding-left: 3rem !important;
    }
}

@media only screen and (min-height: 795px) and (max-height: 905px){
    .content__banner {
        padding-top: 4.5rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-height: 765px) and (max-height: 790px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1370px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 86% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 15px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 50px !important;
    }
}
 .title__banner{
    font-size: 52px;
    color: #000000;
    line-height: 39px;
    font-family: 'IBM Plex Serif';
}

.banner_desc{
    color: #000000;
    font-size: 18px;
    width: 74%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.soca-logo{
    width: 120px;
}

.wrapper {
    background-color: black
}

.copyright{
    left: 0;
    right: 0;
    bottom: 8px;
}

.form-control{
    background-color: transparent;
    color: #000 !important;
    border-color: #383838;
    font-size: 12px
}

label {
    font-size:16px;
    color: #000 !important;
}


.body {
    position: absolute;
    width: 560px;
    height: 430px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 42px;
}

.inner-body p {
    text-align: center;
    margin-top: 7.5rem;
}

.inner-body h5 {
    text-align: center;
    margin-top: 3rem;
    font-size: medium;
}

.main-title {
    margin-left: 1.5rem;
    margin-top: 55px;
}

.main-title h4 {
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 24px;
    margin-left: 8rem;
    margin-top: 50px;
}

.logo {
    margin-bottom: 10px;
}

.google-btn {
    position: absolute;
    width: 400px;
    height: 55px;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    display: flex;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 42px;
    border: #FFFFFF;
}

.google-btn p {
    margin-top: 13px;
    padding-left: 5px;
}

.google-btn img {
    margin-top: 10px;
    margin-left: 70px;
    max-width: 30px;
}

.bg-black, .btn-black{
    background-color: black;
}

.btn-black, .btn-white, .btn-dark{
    border-radius: 10px;
}

.btn-white{
    background-color: white;
}

.btn-dark:disabled h5{
    color: #8C8C8C !important;
}
.btn-dark{
    border-radius: 6px;
    color: white;
    min-height: 45px;
    background-color: #000;
    border: 1px solid #000
}

.btn-black{
    min-height: 40px;
}

.btn-white {
    max-height: 40px;
}

.sign-up-card{
   width: 475px
}

input:focus {
    color: white;
    background-color: transparent;
}

.append {
    right: 0;
    padding-right: 10px;
}

button h5 {
    font-size: 14px;
}

.google-logo {
    width: 28px;
}

@media only screen and (max-width: 860px) {
    a {
        color: #6D75F6;
    }

    .btn-sign-in {
        background: none;
        border: none;
        border-radius: 20px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }

    .btn-sign-in img {
        width: 25px;
    }

    .sign-up-card{
        background-color: transparent !important;
        width: 100%
    }
}
</style>

<style>
::-webkit-scrollbar{
    width: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb{
    background: transparent;
    border-radius: 4px;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover{
    background: #2e2e2e;
}
</style>