class ApiRoute {
    constructor() {
        // Version 1.0

        // General
        this.searchEngine = '/api/general'

        // Dashboard
        this.dashboard = '/api/dashboard'

        // Auth
        this.register = 'api/register'
        this.confirmOtp = 'api/confirm-otp'
        this.signIn = 'api/sign-in'
        this.forgotPassword = 'api/forgot-password'
        this.newPassword = 'api/create-password'

        //Quiz Categories
        this.getProjects = '/api/projects';
        this.getCategories = '/api/categories'
        this.getQuiz = '/api/quiz'
        this.getQuizCreator = '/api/quiz-creator'
        this.getQuestion = '/api/questions'
        this.uploadQuestion = '/api/upload_question'
        this.masterGrades = '/api/master-grades'
        this.learningCategories = '/api/learning-categories'
        this.postQuizEvents = '/api/quiz-events'
        this.getMixContent = '/api/mix-content'

        // Report quiz
        this.reportQuiz = '/api/quiz-report-violations'

        // leaderboard
        this.getQuizResults = '/api/quiz-results'

        // quiz
        this.getQuizTeam = '/api/quiz-events-teams'
        this.getQuizDuel = '/api/quiz-events-duels'

        // Question for Creator
        this.postQuestionCreator = '/api/questions_creator'
        this.uploadQuestionCreator = '/api/upload_question_creator'
        this.getQuestionCreator = '/api/questions_creator'

        // project for sign-in
        this.getTokenClient = 'api/v1/check_token_client'
        this.getProjectIdByEmail = 'api/v1/check_project_id/'

        // create content
        this.postContent = 'api/create-content'

        //user general data
        this.userGeneralData = 'api/user'

        // users
        this.user = 'api/users'

        //users account
        this.account = 'api/account'

        // creator
        this.getCreator = 'api/creator'

        // Master Thumbnails
        this.getThumbnails = 'api/master-thumbnails'

        // Polling
        this.getPolling = 'api/pollings'

        //Quiz Populer
        this.getQuizPopuler = 'api/quiz-popular'

        //Quiz favorite creator
        this.getQuizFavorite = 'api/quiz-creator-favourite'

        //Pollings
        this.getPollings = 'api/pollings'
        this.getQuestionPolling = 'api/polling-questions'

        // Subscriptions
        this.subscriptions = 'api/invoices'

        // Sub Grade
        this.getSubGrade = 'api/master-sub-grades'

        // My Rewards
        this.getRewards = 'api/my-rewards'

        //Creation Interest
        this.creation_interest = 'api/creation-interest'

        //User Interest
        this.user_interest = 'api/user-interest'

        // Check play
        this.getCheckPlay = 'api/check-play'

        // Package Subscriptions
        this.packageSubscriptions = 'api/package-subscriptions'

        // Content
        this.contents = 'api/contents'

        // Get categories for ttq
        this.categoriesTtq = 'api/categories-ttq'

        //Payment Method
        this.paymentMethod = 'api/payment-method'

        //Payment Hisroty
        this.paymentHistory = 'api/payment-history'

        //Get master music
        this.masterMusic = 'master_musics/class-filter'

        //Prosa
        this.prosa = 'prosa'

        //Topup
        this.packageTopUp = 'api/package-top-up'

        // Edit profile
        this.editProfile = 'api/edit-profile'

        // SWC
        this.swcPay = 'api/swc-pay'

        // Community
        this.community = 'api/community'

        // Notification
        this.notifications = 'api/notifications'

        // Master Videos
        this.masterVideos = 'api/master-video-tutorial'

        // News Reader
        this.newsReader = 'api/news-reader'

        // Projects
        this.getContentRecent = '/api/contents/recents'
        this.getFolderProject = '/api/contents/project-folder'

        //auto dub
        this.autodub = 'autodub'

        this.autodubProjects = '/api/auto-dubb/'

        // Plans
        this.plansHistory = 'api/allocations/history-use'
        this.plansUsageProgress = 'api/allocations/usage-progress'
        this.sendFeedbackCancelSubscription = 'api/allocations/feedback-cancel-subscriptions'
        this.cancelSubscription = 'api/allocations/cancel-subscriptions'

        this.payment = 'api/payment/'
        this.voucher = 'api/vouchers/'
    }
}

export default new ApiRoute()