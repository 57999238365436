<template>
    <div class="mobile-modal-container d-flex flex-column">
        <div class="container-fluid">
            <div class="px-3 py-3 mb-2">
                <div class="text-white">
                    <div class="d-flex justify-content-between">
                        <a href="/">
                            <img src="@/assets/landing-page/socaLogoWhite.svg" class="soca-logo"/>
                        </a>
                        <button class="btn-basic" @click="backToHome">
                            <img src="@/assets/landing-page/closeIconWhite.svg" />
                        </button>
                    </div>
                    <div class="mt-4">
                        <div class="accordion text-left" role="tablist">
                            <button class="accordion-button w-100 d-flex align-items-center" type="button" v-b-toggle.accordion-1>
                                <h5 class="m-0 mr-1 title-menu">Products</h5>
                                <i class="bi bi-arrow-down ic-product"></i>
                            </button>
                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                <div class="">
                                    <h5 class="text-white pb-2">AI Creator Studio</h5>
                                    <span class="title-muted my-3">Most advanced Gen AI studio</span>
                                    <div class="pt-3">
                                        <span class="text-white" @click="$router.push({ name : 'Creative-Studio' })">Creative Studio</span>
                                    </div>
                                    <div class="pt-2">
                                        <!-- <span class="text-white ml-2 title-content-menu" @click="$router.push({ name : 'Ai-Proctoring' })">AI Proctoring</span> -->
                                        <span class="text-white title-content-menu">Dubbing Studio <i class="title-muted">(soon)</i></span>
                                    </div>
                                </div>
                                <div class="pt-5 pb-2">
                                    <h5 class="text-white pb-2">AI Magic Tools</h5>
                                    <span class="title-muted my-3">The fastest way to generate content</span>
                                    <div class="pt-4 row mb-3 w-100">
                                        <div class="col-5 ml-2">
                                            <span class="text-white ml-2 title-content-menu" @click="$router.push({name: 'Generate-voices'})">Generate Voices</span>
                                        </div>
                                        <div class="col-6">
                                            <span class="text-white ml-2 title-content-menu" @click="$router.push({ name : 'Clone-voices' })">Clone Voices</span>

                                        </div>
                                        <div class="col-5 ml-2 mt-3">
                                            <span class="text-white ml-2 title-content-menu" @click="$router.push({name: 'Generate-videos'})">Generate Videos</span>

                                        </div>
                                        <div class="col-6 mt-3">
                                            <span class="text-white ml-2 title-content-menu" @click="$router.push({name: 'Speech-Editor'})">Speech Editor</span>
                                        </div>
                                        <div class="col-12 ml-2 mt-4 text-left">
                                            <div class="d-flex flex-column ml-2">
                                                <img src="@/assets/images/q-gen.svg" alt="Q-Gen" class="q-gen--logo">
                                                <span class="qgen--description mt-1">Generate Quizzes to Unleash Geniuses</span>
                                                <span class="text-white mt-3 title-content-menu" style="font-size: 14px;">Elevate learning experiences with AI-Powered Quizzes designed for recruiters, trainers, & learners.</span>
                                                <span class="q-gen--smalltext mt-3">Already have SOCA ID? Get started for free.</span>
                                                <button class="q-gen--button-try btn p-0 text-left mt-1" @click="tryTtq">Try for FREE <span class="bi bi-arrow-right ic-arrow"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>

                            <button class="accordion-button w-100 d-flex align-items-center" type="button" v-b-toggle.accordion-2>
                                <h5 class="m-0 mr-1 title-menu">Solutions</h5>
                                <i class="bi bi-arrow-down ic-product"></i>
                            </button>
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                <!-- <div class="col-12 mb-4">
                                    <iframe src="https://www.youtube.com/embed/nCX8dVRqOjc" title="Today, We Reinvent The AI Quiz Maker with Limitless Creation" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="iframe__container d-flex"></iframe>
                                </div> -->
                               <div class="solutions--list d-flex flex-column mb-3">
                                <p v-for="(data, index) in solutions" :key="index" class="solutions--text m-0" @click="to(data?.to)">
                                    {{ data.name }}
                                    <span class="bi bi-arrow-right ic-arrow"></span>
                                </p>
                               </div>
                            </b-collapse>

                            <!-- <button class="accordion-button w-100 d-flex align-items-center" type="button" v-b-toggle.accordion-2>
                                <h5 class="m-0 title-menu" @click="$router.push({path: '/pricing-plans'})">Pricing</h5>
                            </button> -->

                            <button class="accordion-button w-100 d-flex align-items-center" type="button" v-b-toggle.accordion-3>
                                <h5 class="m-0 title-menu">Company</h5>
                                <i class="bi bi-arrow-down pl-1 ic-product"></i>
                            </button>
                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                <div class="d-flex flex-column justify-content-start align-items-start">
                                    <span @click="$router.push({name: 'About-Us'})" class="py-3">
                                        <div>
                                            <p class="m-0 align-self-center">About Us</p>
                                        </div>
                                    </span>
                                    <span @click="$router.push({name: 'Careers'})" class="pb-3">
                                        <div>
                                            <p class="m-0 align-self-center">Careers</p>
                                        </div>
                                    </span>
                                    <span @click="visit('https://blog.soca.ai/')" class="pb-3">
                                        <div>
                                            <p class="m-0 align-self-center">Blog</p>
                                        </div>
                                    </span>
                                    <span @click="$router.push({name: 'Contact-Us'})" class="pb-3">
                                        <div>
                                            <p class="m-0 align-self-center">Contact Us</p>
                                        </div>
                                    </span>
                                    <span @click="$router.push({name: 'Faq'})" class="pb-3">
                                        <div>
                                            <p class="m-0 align-self-center">FAQ</p>
                                        </div>
                                    </span>
                                </div>
                            </b-collapse>
                        </div>

                        <!-- <div class="accordion accordion-flush" id="accordionFlushExample">

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse"
                                        data-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <h5 class="m-0">Products</h5>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                                    data-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="">
                                            <p>Soca Test</p>
                                            <video width="70%" height="50%" class="rounded-3" autoplay="true">
                                                <source src="../../assets/landing-page/video/animasisocatest.mp4" type="video/mp4">
                                            </video>
                                            <p class="mg-2">Soca Fluence</p>
                                            <video width="70%" height="50%" class="rounded-3" autoplay="true">
                                                <source src="../../assets/landing-page/video/animasisocatest.mp4" type="video/mp4">
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse"
                                        data-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <h5 class="m-0">Company</h5>
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                                    data-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="d-flex flex-column justify-content-start align-items-start">
                                            <a href="#">
                                                <div class="d-flex">
                                                    <img src="@/assets/landing-page/about.svg" />
                                                    <p class="m-0 align-self-center">About</p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div class="d-flex">
                                                    <img src="@/assets/landing-page/contact.svg" />
                                                    <p class="m-0 align-self-center">Contact</p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div class="d-flex">
                                                    <img src="@/assets/landing-page/career.svg" />
                                                    <p class="m-0 align-self-center">Career</p>
                                                </div>
                                            </a>
                                            <a href="#">
                                                <div class="d-flex">
                                                    <img src="@/assets/landing-page/security.svg" />
                                                    <p class="m-0 align-self-center">Security</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="ph__position">
                <a href="https://www.producthunt.com/posts/soca-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-soca&#0045;ai" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=394150&theme=light" alt="Soca&#0032;AI - Democratizing&#0032;creative&#0032;content | Product Hunt" style="width: 250px; height: 46px;" width="250" height="46" /></a>
            </div> -->
        </div>
        <div class="px-3 d-flex justify-content-center my-5">
            <button v-if="usersLogin" class="btn btn-open-studio justify-content-center btn-lg btn-block" @click="goAiGenerator">Open Creator</button>
            <button v-else class="btn btn-purple justify-content-center text-white btn-lg" @click="goToSignIn">LOG IN <i class="bi bi-arrow-right ml-2"></i></button>
        </div>
    </div>
</template>
<script>
import { base_url_qgen, base_url_mobile_creator } from '../../config/base_url';
export default {
    data(){
        return{
            showInnerAccordion: true,
            usersLogin : false,

            solutions: [
                {
                    name : 'Dubbing for Movie',
                    to: 'DubbingForMovie'
                },
                {
                    name : 'AI Reader for Publishers',
                    to: 'AiReaders'
                },
                {
                    name : 'CX Conversation for Chatbot',
                    to: 'CxConversation'
                },
                {
                    name : 'Voice Characters for Game ',
                    to: 'Voice-Characters'
                },
                {
                    name : 'Multilingual for Storyteller',
                    to: 'MultilingualStoryteller'
                },
                {
                    name : 'For Marketing & Sales',
                    to: 'forMarketingSales'
                },
            ]
        }
    },
    methods: {
        backToHome() {
            this.$router.go(-1);
        },
        visit(value){
            window.open(value);
        },
        goToSignIn() {
            // this.$router.push({ name : 'Mobile-Maintenance' })
            window.location.href = base_url_mobile_creator + 'login';
        },
        goAiGenerator() {
            // this.$router.push({ name : 'Mobile-Maintenance' })

            let token = null

            if(localStorage.getItem("users")) {
                var users = JSON.parse(localStorage.getItem("users"))
                if(users.token) {
                    token = users.token
                } else {
                    token = localStorage.getItem("token_client") ? localStorage.getItem("token_client") : null
                }
            } else if (localStorage.getItem("token_client")) {
                token = localStorage.getItem("token_client")
            }
            window.location.href = base_url_mobile_creator + 'login?token=' + token;
        },
        tryTtq() {
            window.open(base_url_qgen);
        },
        to(route) {
            if(route) {
                this.$router.push({ name : route })
            }
         }
    },

    mounted(){
        if(localStorage.getItem('users')){
            this.usersLogin = true
        }
    },
}
</script>
<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
a {
    color: white;
    text-decoration: none;
}
.iframe__container {
    border-radius: 22px;
    width: 100% !important;
    height: 194px;
}
.mobile-modal-container {
    min-height: 100vh;
    height: 100%;
    background-color: #1C1C1C;
}

.btn-open-studio{
    background-color: transparent;
    border: 2px solid #6D75F6;
    color: #6D75F6;
}

.soca-logo {
    width: 100px;
}
.btn-basic {
    background-color: transparent;
    border: none;
}
.accordion {
    --bs-accordion-bg: transparent;
}
.accordion-button.collapsed {
    background: #1C1C1C;
    color: white;
    padding: 1rem 0;
    border-bottom: 0.5px solid #434343 !important;
    border: 0;
}

.collapse.show {
    border-bottom: 0.5px solid #434343 !important;
    border-top: 0.5px solid #434343 !important;
    padding: 1rem 0rem;
}

.accordion-button.collapsed .ic-product::before {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
.accordion-button.collapsed .ic-company::before {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
.accordion-button::after {
    content: "";
    background-image:url("../../assets/landing-page/chevronRightWhite.svg");
    background-size: 10px;
}
.accordion-button:focus {
    border: none;
    box-shadow: none;
}
.accordion-button:not(.collapsed) {
    color: #6D75F6;
    font-weight: bold;
    background-color: #1C1C1C;
    box-shadow: none;
    padding: 16px 0;
    border: 0;
}
.title-menu{
    font-weight: 500;
    font-size: 24px;
}
.accordion-button:not(.collapsed) .ic-product::before, .solution-accordion.accordion-button:not(.collapsed) .ic-company::before {
    transform: rotate(179deg);
    transition: transform 0.2s ease-in-out;
}
.accordion-body {
    background: #1C1C1C;
    color: white;
    padding: 8px 0;
}
.solution-accordion.accordion-button.collapsed {
    border: 1px solid #434343;
    padding: 16px 20px;
}

.solution-accordion.accordion-button.collapsed img{
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
.solution-accordion.accordion-button:not(.collapsed) {
    color: #BC3BF2;
    border: 1px solid #BC3BF2;
    padding: 16px 20px;
}
.solution-accordion.accordion-button:not(.collapsed) img {
    filter: invert(41%) sepia(72%) saturate(5770%) hue-rotate(267deg) brightness(97%) contrast(96%);
}
.btn-basic {
    border: none;
    border-radius: 20px;
}
.space-text-to-audio{
    margin-left: 2.02rem;
}
.btn-contact {
    background-color: #40BE45;
}
.btn-purple {
    display: inline-flex;
    align-items: center;
    width: 358px;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(71 63 244/var(--tw-bg-opacity));
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    --tw-shadow: 0px 4px 9px rgba(0,0,0,.25);
    --tw-shadow-colored: 0px 4px 9px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}
.btn-sign-in {
    margin-top: 20px;
    margin-bottom: 20px;
}

.ph__position {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
}

.title-muted {
    color: #999999;
}

.qgen--description {
    color: #999;
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.q-gen--logo {
    width: 100px;
}

.q-gen--smalltext {
    color: #999;
    font-family: Avenir Next;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.q-gen--button-try {
    color: #6D75F6;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    transition: color 0.25s;
}

.q-gen--button-try:hover, .q-gen--button-try:active {
    color: #545EED;
}

.solutions--list {
    gap: 14px;
}

.solutions--text {
    font-size: 18px;
    font-weight: 500;
    color: white;

    transition: color 0.25s;
}

.solutions--text:hover, .solutions--text:active {
    color: #6D75F6;
}

.ic-arrow {
    position: absolute;
    transform: translateX(-17px);
    opacity: 0;
    transition: transform 0.3s ease-in-out;
}

.solutions--text:hover > .ic-arrow, .solutions--text:active > .ic-arrow{
  transform: translateX(3px);
  opacity: 1;
}

.ic-arrow {
    font-size: 20px;
}

@media only screen and (min-width: 200px) and (max-width: 600px)  {
    h5{
        font-size: 1.2rem;
    }
    span{
        font-size: 1rem;
    }
}
</style>