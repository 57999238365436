import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getReportPollings(){
    return axios({
        method : 'GET',
        url : ApiRoute.getPolling + "/report/63637127ffc416ee7a9283d5"
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }

  export async function addPolling(formData) {
    return axios.post(ApiRoute.getPollings, formData)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
      })
  }