import axios from "../../config/axios";
import ApiRoute from "../api.route";
import {base_url_landing_page} from "../../config/base_url";

export async function forgotPassword(data_post) {

    return axios.post(base_url_landing_page + ApiRoute.forgotPassword, data_post)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}