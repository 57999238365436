import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function addQuestionPolling(formData, id) {
    return axios.post(ApiRoute.getQuestionPolling +'/'+id, formData)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
      })
  }