import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getQuizCreator() {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizCreator
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
      page: res.data.page,
      limit: res.data.limit,
      totalPage: res.data.totalPage,
      totalData: res.data.totalData,
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizCreatorAll() {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizCreator + '/all'
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizCreatorSimilar(id) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizCreator + '/similar/' + id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizCreatorByIdUser(id) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizCreator + '/creator/' + id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizCreatorById(id, params) {
  return axios.get(ApiRoute.getQuizCreator + '/' + id, {params: params})
  .then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message,
    }
    return data;
  }).catch((error) => {
    const data = {
      status: false,
      response: error
    }
    return data;
  })
}

export async function addQuizCreator(formData) {
  return axios.post(ApiRoute.getQuizCreator, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function updateQuizCreator(formData, id) {
  return axios.put(ApiRoute.getQuizCreator + '/' + id, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function deleteQuizCreator(id) {
  return axios.delete(ApiRoute.getQuizCreator + '/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function getUserRatingCreator(id) {
  return axios.get(ApiRoute.getQuizCreator + '/check-rating/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function creatorUserRating(id) {
  return axios.put(ApiRoute.getQuizCreator + '/rating/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function creatorUserUnrating(id) {
  return axios.put(ApiRoute.getQuizCreator + '/unrating/' + id).then((res) => {
    const data = res.data
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function postContent(formData){
  return axios.post(ApiRoute.postContent, formData)
  .then(res => {
    const data = res.data
    return data;
  })
  .catch((error) => {
    const data = { status: false, response: error }
    console.log(error)
    return data;
  })
}

export async function getQuizFavorite() {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizFavorite
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizCreatorDraft() {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizCreator + '/drafts'
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getContentRecentAll() {
  return axios({
    method: 'GET',
    url: ApiRoute.getContentRecent
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}
export async function getDataProjectFolderAll() {
  return axios({
    method: 'GET',
    url: ApiRoute.getFolderProject
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}