<template>
<div>
    <nav class="navbar bg-black">
        <a class="navbar-brand mx-4" href="#"><img src="@/assets/landing-page/soca-text-putih.svg" alt=""></a>
    </nav>

    <div v-if="$route.query.role == 1">
        <div class="my-5 py-5" :class="viewportWidth > 860 ? ' container' : ' container-fluid'">
            <h1 class="fw-semibold text-center mb-0">Saya tertarik dengan</h1>
            <div :class="viewportWidth > 860 ? ' container' : 'container-fluid'">
                <div class="row justify-content-center" :class="viewportWidth > 860 ? '' : ' mt-4'">
                    <div class="col-auto my-2 mr-2" v-for="(data, index) in selectBelajar" :key="index">
                        <a class="badge text-decoration-none" :class="data.active ? 'badge-active' : 'badge-nonactive'" @click=" data.active ? removed(data.id) : choosed(data.id)" href="#"><h5 class="mx-3 my-2 fw-bold">{{data.interest}}</h5></a>
                    </div>
                </div>
            </div>
            <button 
                type="button" 
                class="btn btn-primary btn-lg btn-select" 
                :class="viewportWidth > 860 ? '' : 'mt-5 w-100'"
                @click="finishRegistration()"
            >
                <h5 class="mx-4 my-1">Selesai</h5>
            </button>
        </div>
    </div>

    <div v-else>
        <div class="my-5" :class="viewportWidth > 860 ? ' py-5 container' : ' container-fluid'">
            <h1 class="fw-semibold text-center mb-0">Saya hebat dalam</h1>
            <div :class="viewportWidth > 860 ? ' container' : 'container-fluid'">
                <div class="row justify-content-center" :class="viewportWidth > 860 ? '' : ' my-2'">
                    <div class="col-auto my-2 mr-2" v-for="(data, index) in selectKreasi" :key="index">
                        <a class="badge text-decoration-none" :class="data.active ? 'badge-active' : 'badge-nonactive'" @click=" data.active ? removed(data.id) : choosed(data.id)" href="#"><h5 class="mx-4 my-2 fw-bold">{{data.interest}}</h5></a>
                    </div>
                </div>
            </div>
            <button 
                type="button" 
                class="btn btn-primary btn-lg btn-select"
                :class="viewportWidth > 860 ? '' : 'mt-2 w-100'" 
                @click="finishRegistration()"
            >
                <h5 class="mx-4 my-1">Selesai</h5>
            </button>
        </div>
    </div>
</div>
</template>
<script>
import Swal from 'sweetalert2';
import { getCategoriesAll } from "@/services/quiz-categories/quiz_categories.service";
import {getCreationInterest} from '@/services/creation_interests/creation-interest.service'
import {createUserInterest} from '@/services/user_interests/user-interest.service'

export default {
    data() {
        return {
            selectedBelajar: [],
            selectedKreasi : [],
            selectBelajar : [],
            selectKreasi : [],
            formData: [],
            viewportWidth: window.innerWidth,
        }   
    },
    mounted() {
        this.getCategories()
        this.getCreationInterest()
    },
    methods :{
        getCategories(){
            getCategoriesAll().then((response) => {
                if(response.status){
                    for(var index=0; index < response.data.length; index++ ){
                        var dataLearnInterest = {
                            id:index + 1,
                            _id: response.data[index]._id,
                            interest:response.data[index].category_name,
                            active:false
                        }

                        this.selectBelajar.push(dataLearnInterest)
                    }
                }
            })
        },
        getCreationInterest(){
            getCreationInterest().then((response) => {
                if(response.status){
                    for(var index=0; index < response.data.length; index++ ){
                        var dataCreationInterest = {
                            id:index + 1,
                            _id: response.data[index]._id,
                            interest:response.data[index].interest_name,
                            active:false
                        }

                        this.selectKreasi.push(dataCreationInterest)
                    }
                }
            })
        },
        choosed(item){
            if(this.$route.query.role == 1){
                this.selectedBelajar.push(this.selectBelajar[item-1]);
                this.selectBelajar[item-1].active = true
            } else {
                this.selectedKreasi.push(this.selectKreasi[item-1]);
                this.selectKreasi[item-1].active = true
            }
        },
        removed(item){
            if(this.$route.query.role == 1){
                this.selectBelajar[item-1].active = false
                const index = this.selectedBelajar.findIndex(x => x.id == item)
                this.selectedBelajar.splice(index, 1)
            } else {
                this.selectKreasi[item-1].active = false
                const index = this.selectedKreasi.findIndex(x => x.id == item)
                this.selectedKreasi.splice(index, 1)
            }
        },
        finishRegistration(){
            if(this.selectedBelajar.length != 0){
                this.selectedBelajar.forEach((data) => {
                    var dataInsert = {
                        user_id : this.$route.query.go,
                        interest_type : this.$route.query.role,
                        interest_id : data._id
                    }

                    this.formData.push(dataInsert)
                })

                console.log("Form Data", this.formData)
            } else {
                this.selectedKreasi.forEach((data) => {
                    var dataInsert = {
                        user_id : this.$route.query.go,
                        interest_type : this.$route.query.role,
                        interest_id : data._id
                    }

                    this.formData.push(dataInsert)
                })

                console.log("Form Data", this.formData)
            }

            createUserInterest(this.formData).then((response) => {
                if(response.status){
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Registrasi Akun anda berhasil',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                    this.$router.push({ name: 'SignIn' })
                } else {
                    Swal.fire({
                        title: 'Ooopss!',
                        text: 'Peringatan pada server',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.bg-black {
    background-color: black !important
}

.badge{
    border-radius: 25px;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 10px;
}
.badge-active {
    background: rgb(207,212,255);
    background: linear-gradient(0deg, rgba(207,212,255,1) 0%, rgba(255,255,255,1) 100%);
    border: 2px solid #7280FF;
    color: #000;
}
.badge-nonactive {
    background-color: #fff;
    border: 2px solid #D9D9D9;
    color: #000;
}

.btn-select{
    border-radius: 25px;
    background-color: #7280FF;
    border-color: #7280FF;
}

.bg-black{
    background-color: black;
}

@media only screen and (max-width: 860px) {
    .navbar-brand img {
        width: 70px;
    }
}
</style>