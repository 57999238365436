import { getProjects } from '../services/projects/projects.service'
export default (to,from,next) => {
    if(localStorage.getItem('users')){
        getProjects()
        .then((response) => {
            if(response.status == true) {
                next()
            } else {
                localStorage.clear()
                sessionStorage.clear()
                next('/login')
            }
        })
        .catch(() => {
            localStorage.clear()
            sessionStorage.clear()
            next('/login')
        })
    } else {
        localStorage.clear()
        sessionStorage.clear()
        next('/login')
    }
}