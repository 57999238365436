import axios from "../../config/axios";
import ApiRoute from "../api.route";
import {base_url_landing_page} from "../../config/base_url";

export async function getCreationInterest() {
    return axios({
        method: 'GET',
        url: base_url_landing_page + ApiRoute.creation_interest
    })
    .then((res) => {
        const data = {
            status: res.data.status,
            data: res.data.data,
            message: res.data.message
        }
        return data;
    })
    .catch((error) => {
        const data = {
            status: false,
            response: error
        }
        return data;
    })
}