import axios from "../../config/axios";
import ApiRoute from "../api.route";
import {base_url_landing_page} from "../../config/base_url";

export async function confirmOtp(data_post) {
    let token_client = ""

    if(localStorage.getItem('token_client')){
        token_client = localStorage.getItem('token_client')
    }

    return axios.post(base_url_landing_page + ApiRoute.confirmOtp, data_post,{headers : { "token_client" : token_client }})
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}