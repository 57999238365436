<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>

export default {
  metaInfo() {
    return {
      title: 'Generative AI Voice Cloning and Auto Dubbing',
      meta : [
                {
                    name: 'description',
                    content: 'We are on the mission to democratize creative learning. Empower and enable everyone to become digital educator or creator.',
                },
                {

                    property: 'og:title',
                    content: 'SOCA AI Quiz — The world’s most engaging learning platform',
                }
            ]
    }
  },
}
</script>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("./assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("./assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("./assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Avenir Next";
  src: url("./assets/font/AvenirNext-Medium.otf");
  font-weight: 500;
}

@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");
#app {
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body, #app{
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
img{
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
html {
  scroll-behavior: smooth;
}
.form-control {
    border-color: #383838 !important;
    box-shadow: none !important;
}
</style>
