<template>
    <div class="h-100">

        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-12 d-flex flex-column align-items-center justify-content-between text-white">
                    <div class="container d-flex align-items-center pl-3 pl-md-4 pt-3 text-white cursor-pointer" @click="$router.go(-1)">
                    
                    </div>
                    <div class="verif-card text-white">
                        <div class="card-body">
                            <h2 class="mt-4 mb-1 text-dark" style="font-size:26px;">{{ $t('verification-code') }}</h2>
                            <p class="mt-3 mb-4 text-dark" style="font-size:16px;">{{ $t('verification-text') }}</p>

                            <div class="mx-md-5 my-3">
                                <form @submit.prevent="submitOtp">
                                    <div style="display: flex; flex-direction: row;" class="my-4 mx-md-3 verifikasi-otp d-flex justify-content-center">
                                        <v-otp-input
                                            ref="otpInput"
                                            input-classes="otp-input form-control text-center"
                                            separator="&nbsp;&nbsp;&nbsp;"
                                            :num-inputs="6"
                                            :should-auto-focus="true"
                                            :is-input-num="true"
                                            @on-change="handleOnChange"
                                            @on-complete="handleOnComplete"
                                            />
                                    </div>
                                    <button class="btn btn-dark btn-md btn-block mt-3" :disabled="!otp">
                                        <!-- <div v-if="loading" class="spinner-border text-light" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div> -->
                                        <h5 v-if="loading" class="my-1 text-white">Loading...</h5>
                                        <h5 v-else class="my-1 text-white">{{ $t('submit') }}</h5>
                                    </button>
                                </form>
                                <p class="my-4 text-dark">{{ $t('option-Resend-otp') }} <span style="color:#6D75F6;" class="cursor-pointer" @click="resendOtp">{{ $t('resend') }}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="copyright text-white mb-5">
                        <small style="color:#A6A6A6;">Copyright &copy; 2024 Soca.ai All Reserved</small>
                    </div>
                </div>

            </div>
        </div>

        <OtpNotValid />
        <OtpSuccess />
    </div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
import { confirmOtp } from "@/services/confirm-otp/confirm_otp.service";
import { confirmPinOtp, requestSetPin } from "@/services/pin/pin.service";
import { createSwcPayment } from "@/services/swc_payment/swc_payment.service";
import OtpNotValid from '@/components/modal/OtpNotValid'
import OtpSuccess from '@/components/modal/OtpSuccess'

export default {
    mixins: [AlertUtils],
    components:{
        OtpNotValid,
        OtpSuccess
    },
    data() {
        return {
            otp: null,
            loading: false
        }
    },

    methods: {
        handleOnComplete(value) {
            this.otp = value;

        },
        handleOnChange(value) {
            this.otp = value;
        },
        submitOtp(){
            this.loading = true;

             let formData = {
                otp : this.otp
            }

            if(this.$route.query.key == 'set-pin') {
                confirmPinOtp(formData)
                .then((response) => {
                    if(response.status) {
                        if(this.$route.query.request != undefined && this.$route.query.request == 'payment'){
                            let formData = {
                                "package_id" : this.$route.query.package
                            }

                            createSwcPayment(formData)
                            .then((res) => {
                                if(res.status) {
                                    this.loading = false;
                                    this.alertSuccess(this.$t('otp-is-correct'))
                                    this.$router.push({ name : 'Swc-Payment', query : { key : res.data.top_up_swc_transaction_id } })
                                } else {
                                    this.alertFail(res.message)
                                    this.$router.push({ name : 'TopUp' })
                                }
                            })
                        } else if(this.$route.query.request != undefined && this.$route.query.request == 'change-pin' ) {
                            this.alertSuccess(this.$t('otp-is-correct'))
                            this.$router.push({ name : 'MyAccount' })
                        } else {
                            this.alertSuccess(this.$t('otp-is-correct'))
                            this.$router.push({ name : 'Swc-Payment', query : { key : this.$route.query.package } })
                        }
                    } else {
                        console.log(response)
                        this.loading = false;
                        this.alertFailIcon(this.$t('otp-incorrect'))
                    }
                })
            } else {
                confirmOtp(formData)
                .then((response) => {
                    if(response.status == true){
                        this.loading = false;
                            if(this.$route.query.key == 'forgot-password'){
                                this.alertSuccess(this.$t('otp-is-correct'))
                                if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                                    this.$router.push({ name : 'New-Password', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                                } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                                    this.$router.push({ name : 'New-Password', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                                } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                                    this.$router.push({ name : 'New-Password', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                                } else {
                                    this.$router.push({ name : 'New-Password' })
                                }
                            } else if(this.$route.query.key == 'set-pin') {
                                if(this.$route.query.request != undefined && this.$route.query.request == 'set-pin'  ){
                                    this.$router.push({ name : 'New-Password', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                                }
                            } else {
                                this.alertSuccess('Sign Up Success')
                                if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                                    this.$router.push({ name : 'SignIn', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
                                } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                                    this.$router.push({ name : 'SignIn', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
                                } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                                    this.$router.push({ name : 'SignIn', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
                                } else {
                                    this.$router.push({ name : 'SignIn' })
                                }

                                localStorage.removeItem("token_client")
                            }
                    } else {
                        this.loading = false;
                        this.alertFailIcon(this.$t('otp-incorrect'))
                    }
                })
            }

        },
        resendOtp() {
            requestSetPin()
            .then((response) => {
                if(response.status == true) {
                    this.alertSuccess(this.$t('otp-sent-email'))
                } else {
                    if(response.response?.response?.status == 429) {
                        this.alertFail("Too many requests, please try again later.")
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.wrapper {
    height: 100vh;
    background-color : black;
}

.verif-card{
    width: 475px
}

.card__banner{
    background: linear-gradient(223deg, #D9BEBE 0%, #A69DE5 100%);
}

.soca-logo {
    width: 125px;
}
.content__banner{
    padding-top: 3rem;
    padding-left: 3rem;
}
.forgot__container{
    width: 475px;
}
.auth__banner{
    width: 96%;
    position: absolute;
    bottom: 0;
}
.card{
    border-radius: 0;
    background-color: #121418 !important;
}
.img__auth{
    width: 79%;
    height: 100% !important;
    border-left: solid 3px transparent;
    border-top: solid 3px transparent;
    border-top-left-radius: 20px;
    border-color: #ffffff;
    /* background-image: linear-gradient(white, white), linear-gradient(135deg, #473FF4 0%, #E52C96 100%);
    background-origin: border-box;
    background-clip: content-box, border-box; */
}

@media only screen and (min-height: 795px) and (max-height: 905px){
    .content__banner {
        padding-top: 4.5rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
    .banner_desc {
        font-size: 18px !important;
        width: 92% !important;
    }

    .title__banner{
        font-size: 62px !important;
        line-height: 44px !important;
    }

    .img__auth{
        width: 94% !important;
    }

    .content__banner {
        padding-top: 5rem !important;
        padding-left: 3rem !important;
    }
}
@media only screen and (min-height: 765px) and (max-height: 790px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1370px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 86% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 15px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 50px !important;
    }
}
 .title__banner{
    font-size: 52px;
    color: #000000;
    line-height: 39px;
    font-family: 'IBM Plex Serif';
}

.banner_desc{
    color: #000000;
    font-size: 18px;
    width: 74%;
}
.form-control{
    background-color: transparent !important;
    color: #000 !important;
    border-color: #383838;
}


.btn-dark:disabled h5{
    color: #8C8C8C !important;
}
.btn-dark{
    border-radius: 6px;
    background-color: #000;
    border: 1px solid #000;
    color: white;
}

.copyright{
    left: 0;
    right: 0;
    bottom: 0px;
}

.wrapper .container-fluid{
    height: 90vh;
}

@media only screen and (max-width: 860px) {
    .verif-card{
        background-color: transparent !important;
        width: 100%;
    }

    .soca-logo {
        width: 8rem;
    }
}
</style>

<style scoped lang="less">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 22px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<style>
.verifikasi-otp .form-control:focus{
    color: #000 !important;
}
.verifikasi-otp .form-control{
    color: #000;
    background-color:transparent;
    border: 1px solid #383838;
    width: 50px;
    height: 50px;
    padding: 0;
}

.verifikasi-otp input:focus{
    color: white;
    background-color:transparent;
}
</style>
