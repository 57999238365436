import axios from "../../config/axios"
import ApiRoute from '../api.route';

  
  export async function getSearchEngine(data){
    return axios.get(ApiRoute.searchEngine,{ params: data})
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        console.log(error)
        return data;
    })
  }