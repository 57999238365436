<template>
  <div>
    <b-modal
      id="email-berhasil-terdaftar-modal"
      centered
      header-class="border-0 pt-4 pb-0"
      :hide-footer="true"
      :hide-header="true"
      footer-class="border-0 pb-3 pt-0 mx-auto"
      size="sm"
      no-close-on-backdrop
    >
      <div class="float-right">
        <button
          class="btn d-flex justify-content-center align-items-center pr-0"
          @click="next"
        >
          <img src="@/assets/images/icons/ic_close.svg" alt="" />
        </button>
      </div>
      <div class="mt-5 text-center mb-3">
        <img src="@/assets/images/icons/modal_success.svg" alt="Sign Up Success" />
        <div class="mt-4 text-white">
          <h4 class="font-weight-bold mb-4">Sign Up Success</h4>
          <h5 class="font-weight-normal">
            Your email has been successfully registered.
          </h5>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button
            v-if="!loading"
            class="btn btn-purple"
            @click="next"
          >
            <p class="mx-2 mb-0 text-white font-weight-bold">Sign in now <span class="bi bi-arrow-right ic-product"></span></p>
          </button>
          <button
            v-else
            class="btn btn-purple"
          >
          <div class="mx-3">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
          </div>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getProjects } from "@/services/projects/projects.service";
import { getPlay } from '@/services/quiz/quiz.service'
import { subscriptions } from '@/services/subscriptions/subscriptions.service'

export default {
  data() {
    return {
        loading: false
    };
  },

  methods: {
    next() {
        var vm = this
        vm.loading = true
      if (
        vm.$route.query.request != undefined &&
        vm.$route.query.request == "play"
      ) {
        getProjects().then((resp) => {
          if (resp.status == true) {
            if (vm.$route.query.author == 0) {
              let origin = { origin: "template" };
              getPlay(vm.$route.query.idQuiz, origin).then((response) => {
                if (response.status && response.status == true) {
                  console.log(response);
                  window.location =
                    "https://play.soca.ai/instance/template/" +
                    vm.$route.query.idQuiz +
                    "/" +
                    resp.data._id +
                    "/" +
                    vm.$route.query.category;
                }
              });
            } else if (vm.$route.query.author == 1) {
              let origin = { origin: "creator" };
              getPlay(vm.$route.query.idQuiz, origin).then((response) => {
                if (response.status && response.status == true) {
                  window.location =
                    "https://play.soca.ai/instance/creator/" +
                    vm.$route.query.idQuiz +
                    "/" +
                    resp.data._id +
                    "/" +
                    vm.$route.query.category;
                  console.log(response);
                }
              });
            }
          } else {
            vm.$bvModal.hide('email-berhasil-terdaftar-modal')
            vm.loading = false
          }
        });
      } else if (
        vm.$route.query.request != undefined &&
        vm.$route.query.request == "upgrade_plan"
      ) {
        vm.$vs.loading({
          type: "sound",
        });

        let payload = {
          package_id: vm.$route.query.package_id,
        };

        subscriptions(payload).then((response) => {
          if (response.status == true) {
            vm.loading = false
            vm.$vs.loading.close();
            vm.$bvModal.hide('email-berhasil-terdaftar-modal')
            vm.$router.push({
              name: "Subscriptions",
              query: { id: response.data.subscription_id },
            });
          } else {
            vm.$vs.loading.close();
          }
        });
      } else {
        vm.loading = false
        vm.$bvModal.hide('email-berhasil-terdaftar-modal')
        vm.$router.push({ name: "My-Quiz" });
      }
    },
  },
};
</script>

<style scoped>
.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#email-berhasil-terdaftar-modal .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#email-berhasil-terdaftar-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#email-berhasil-terdaftar-modal .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

button {
  box-shadow: none !important;
}
</style>