<template>
<div class="position-relative h-100">
    <div class="container-fluid h-100 text-white">
        <div class="row h-100">
            <div class="col-12 align-items-center d-flex justify-content-center">
                <div class="login-card">
                    <div class="card-body">
                        <h2 class="mt-4 mb-5 mt-md-3 mb-md-1" style="font-size:26px;">Log in to your account</h2>
                        <div class="login-session mx-md-5 py-3">
                            <!-- SEAMLESS SIGN IN -->
                            <button class="btn btn-white btn-block btn-md d-flex align-items-center justify-content-center" @click="newOauth"><img src="@/assets/landing-page/google.svg" class="mr-2 img-fluid google-logo" alt="ios"><h5 class="" style="font-size:16px; color: #000 !important;">{{ $t('signin-google') }}</h5></button>

                            <p class="mb-0 mt-3 text-dark" style="color: #000 !important;">{{ $t('or') }}</p>

                            <!-- FORM SIGNIN -->
                            <form @submit.prevent="goSignIn">
                                <div class="form-group text-left">
                                    <label for="txt-email">Email</label>
                                    <input type="email" class="form-control" :placeholder="$t('email--placeholder')" id="txt-email" v-model="email">
                                </div>
                                <div class="form-group text-left">
                                    <label for="txt-password">{{ $t('password') }}</label>
                                    <div class="position-relative d-flex align-items-center">
                                        <input :type="typeInput" class="form-control" :placeholder="$t('password-input--placeholder')" id="txt-password" v-model="password" style="padding-right:35px">
                                        <div class="append position-absolute cursor-pointer" @click="showPassword">
                                            <img :src="require(`@/assets/images/icons/${ic_eye}`)" alt="Show Password" class="ic-eye">
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <span class="cursor-pointer" @click="toForgotPassword()" style="color:#6D75F6; font-size:14px; font-weight: 600;">{{ $t('forgot-password') }}</span>
                                </div>

                                <button class="btn btn-dark btn-md btn-block mt-3" :disabled="!email || !password">
                                    <h5 v-if="processing || loading" class="text-white" style="font-size:16px;">Loading...</h5>
                                    <!-- <div v-if="processing" class="spinner-border text-light" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> -->
                                    <h5 v-else class="text-white" style="font-size:16px;">{{ $t('sign-in') }}</h5>
                                </button>
                            </form>
                            <p class="mb-0 mt-3 text-dark" style="font-size:14px; font-weight: 400; color: #000 !important;">{{ $t('option-donthaveaccount') }} <span class="cursor-pointer" @click="toSignUp" style="color:#6D75F6; font-weight: 600;">{{ $t('option-donthaveaccount-su') }}</span></p>
                        </div>
                    </div>
                </div>
                <div class="copyright text-white position-absolute mb-5">
                    <small style="color:#A6A6A6;">Copyright &copy; 2024 Soca.ai All Reserved</small>
                </div>
            </div>
        </div>
    </div>
    <EmailTidakTerdaftar />
    <EmailPasswordSalah />
</div>
</template>
<script>
import Swal from 'sweetalert2';
import axios from '@/config/axios';
import { getTokenByEmail, getProjects } from "@/services/projects/projects.service";
import { getPlay } from '@/services/quiz/quiz.service'
import { subscriptions,topupSwc } from '@/services/subscriptions/subscriptions.service'
import { getUser } from '@/services/user/user.service'
import { base_url_general, base_url_mobile_creator } from '@/config/base_url'
import { getListInvitationCommunity } from '@/services/community/community.service'

import EmailTidakTerdaftar from '@/components/modal/EmailTidakTerdaftar'
import EmailPasswordSalah from '@/components/modal/EmailPasswordSalah'
import {mapGetters,mapActions } from "vuex";

export default {
    name: "sign-up",
    components: {
        EmailTidakTerdaftar,
        EmailPasswordSalah
    },
    data: function () {
        return {
            viewportWidth: window.innerWidth,
            email: null,
            password: null,
            typeInput: 'password',
            status_show_password : false,
            loading: false,
            ic_eye : 'ic_eye_dark.svg'
        }
    },
    computed:{
        ...mapGetters(["users", "processing", "loginError"])
    },
    watch:{

        users(val){
            if(val != null ){
                this.checkRequestLogin()
            }
        },

        loginError(val){
            if(val != null ){
                if(val.response.error == 'No such user found') {
                    this.$bvModal.show('email-tidak-terdaftar-modal')
                } else if(val.response.error == 'Error signing in') {
                    this.$bvModal.show('email-password-salah-modal')
                } else if(val.response.error == 'Incorrect email or password') {
                    this.$bvModal.show('email-password-salah-modal')
                }
                this.setProcess(false)
            }
        }
    },
    mounted(){
        console.log(window.screen.height);
        this.axiosInstance = axios.create({
            headers : ""
        })
        if(this.users != null){
            this.checkRequestLogin()
            // this.$router.push({ name: 'Home'})
        }

        if(this.$route.query.token != undefined) {
            localStorage.setItem("token_client", this.$route.query.token)
            this.getUser()
        } else if(this.$route.query.failed == 'user-not-found') {
            this.$bvModal.show('email-tidak-terdaftar-modal')
            localStorage.removeItem("token_client")
        } else {
            localStorage.removeItem("token_client")
        }

        this.cekMediaScreenWidth()
        window.addEventListener("resize", this.cekMediaScreenWidth);
    },
    methods: {
        ...mapActions(["login", "processingFalse"]),

        cekMediaScreenWidth(){
            if(window.innerWidth < 800) {
                window.location.href = base_url_mobile_creator + 'login';
            }
        },

        newOauth() {
            if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                window.location.href = base_url_general + 'auth/google?request=play&idQuiz=' + this.$route.query.idQuiz + '&category=' + this.$route.query.category + '&author=' + this.$route.query.author + '&platform=soca';
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                window.location.href = base_url_general + 'auth/google?request=use-template&idTemp=' + this.$route.query.idTemp + '&author=' + this.$route.query.author + '&platform=soca';
            } else {
                window.location.href = base_url_general + 'auth/google?platform=soca'
            }
        },

        getUser() {
            this.loading = true;
            getUser()
            .then((response) => {
                if(response.status) {
                    localStorage.setItem("users", JSON.stringify(response.data))
                    this.checkRequestLogin()
                    this.loading = false;
                } else {
                    localStorage.removeItem("token_client")
                    this.loading = false;
                }
            })
            .catch((err) => {
                console.log(err)
                this.loading = false;
            })
        },

        signInWithGoogle() {
            var vm = this
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                        console.log(response)
                    this.axiosInstance.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`
                        }
                    })
                    .then((res) => {
                        const email = res.data.email;
                        return getTokenByEmail(email);
                    })
                    .then((res) => {
                        if(res.status) {
                            localStorage.setItem("users", JSON.stringify(res.data))
                            if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                                getProjects()
                                .then((resp) =>{
                                    if(resp.status == true){
                                        if(this.$route.query.author == 0){
                                            let origin = { origin: 'template' }
                                            getPlay(this.$route.query.idQuiz, origin)
                                            .then((response) => {
                                                if(response.status && response.status == true){
                                                    window.location = "https://play.soca.ai/instance/template/" + this.$route.query.idQuiz + "/" + resp.data._id + "/" + this.$route.query.category
                                                }
                                            })
                                        } else if (this.$route.query.author == 1){
                                            let origin = { origin: 'creator' }
                                            getPlay(this.$route.query.idQuiz, origin)
                                            .then((response) => {
                                                if(response.status && response.status == true){
                                                    window.location = "https://play.soca.ai/instance/creator/" + this.$route.query.idQuiz + "/" + resp.data._id + "/" + this.$route.query.category
                                                }
                                            })
                                        }
                                    }
                                })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                                this.$vs.loading({
                                    type: 'sound'
                                });

                                let payload = {
                                    package_id : this.$route.query.package_id
                                }

                                subscriptions(payload)
                                .then((response) => {
                                    if(response.status == true){
                                        this.$vs.loading.close();
                                        this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id}})
                                    } else {
                                        this.$vs.loading.close();
                                    }
                                })
                            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template'){
                                this.$router.push({ name: 'Create-My-Quiz', query: {idTemp: this.$route.query.idTemp, author: this.$route.query.author} })
                            } else {
                                getListInvitationCommunity()
                                .then((response) => {
                                    if(response.status) {
                                        this.$router.push({ name : 'Community-New-Member-Confirmations' })
                                    } else {
                                        this.$router.push({ name : 'Home' })
                                    }
                                })
                            }

                        } else {
                            vm.$bvModal.show('email-tidak-terdaftar-modal')
                        }
                    })
                },
            });

            client.requestAccessToken();
        },
        signInWithGoogleForMobile() {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                    axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`
                        }
                    })
                        .then((res) => {
                            const email = res.data.email;
                            return getTokenByEmail(email);
                        })
                        .then((res) => {
                            if (res.status) {
                                window.location = "https://creator.soca.ai/auth_key/" + res.data._id;
                                // window.location = "https://creator.soca.ai/we-are-sorry"
                            } else {
                                Swal.fire({
                                    title: 'Peringatan!',
                                    text: 'Email tidak terdaftar',
                                    icon: 'warning',
                                    confirmButtonText: 'Kembali'
                                })
                            }
                        })
                },
            });

            client.requestAccessToken();
        },

        getProject() {
            this.$vs.loading({
                type: 'sound'
            });

            getProjects()
            .then((response) => {
                if(response.status && response.status == true){
                    localStorage.setItem('users', JSON.stringify(response.data))
                    this.$router.push({ name: 'Home' })
                    this.$vs.loading.close();
                }else{
                    localStorage.removeItem('users')
                    this.$vs.loading.close();
                }
            }).catch((err) => {
                this.$vs.loading.close();
                console.log("data err", err)
            });
        },

        goSignIn(){
            this.login({ email : this.email, password : this.password})
        },

        checkRequestLogin(){
            if(this.$route.query.request != undefined && this.$route.query.request == 'play'){
                getProjects()
                .then((resp) =>{
                    if(resp.status == true){
                        if(this.$route.query.author == 0){
                            let origin = { origin: 'template' }
                            getPlay(this.$route.query.idQuiz, origin)
                            .then((response) => {
                                if(response.status && response.status == true){
                                    window.location = "https://play.soca.ai/instance/template/" + this.$route.query.idQuiz + "/" + resp.data._id + "/" + this.$route.query.category
                                }
                            })
                        } else if (this.$route.query.author == 1){
                            let origin = { origin: 'creator' }
                            getPlay(this.$route.query.idQuiz, origin)
                            .then((response) => {
                                if(response.status && response.status == true){
                                    window.location = "https://play.soca.ai/instance/creator/" + this.$route.query.idQuiz + "/" + resp.data._id + "/" + this.$route.query.category
                                }
                            })
                        }
                    }
                })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                this.$vs.loading({
                    type: 'sound'
                });

                let payload = {
                    package_id : this.$route.query.package_id
                }

                subscriptions(payload)
                .then((response) => {
                    if(response.status == true){
                        this.$vs.loading.close();
                        this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id}})
                    } else {
                        this.$vs.loading.close();
                    }
                })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template'){
                this.$router.push({ name: 'Create-My-Quiz', query: { idTemp: this.$route.query.idTemp, author: this.$route.query.author} })
            } else if (this.$route.query.request != undefined && this.$route.query.request == 'soca-platform') {
                this.$router.push({ name : 'Generate-Videos', params: { mode: this.$route.query.mode, id: this.$route.query.id } })
            } else if (this.$route.query.request != undefined && this.$route.query.request == 'news-reader-edit') {
                this.$router.push({ name : 'NewsReader', params: { mode: this.$route.query.mode, id: this.$route.query.id } })
            } else if (this.$route.query.request != undefined && this.$route.query.request == 'multilingual-promo') {
                let formData = {
                    package_id : this.$route.query.package_id,
                    community_id : null
                }

                topupSwc(formData)
                .then((response) => {
                    if(response.status == true){
                        this.load_topup = false;
                        this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
                    }
                })
                .catch((err) => {
                    this.load_topup = false;
                    console.log(err)
                })
            } else {
                getListInvitationCommunity()
                .then((response) => {
                    if(response.status) {
                        this.$router.push({ name : 'Community-New-Member-Confirmations' })
                    } else {
                        this.$router.push({ name : 'Home' })
                    }
                })
            }
        },

        setProcess(val){
            this.processingFalse(val)
        },

        showPassword(){
            if(this.status_show_password){
                this.status_show_password = false
                this.ic_eye = 'ic_eye_dark.svg'
                this.typeInput = 'password'
            } else {
                this.status_show_password = true
                this.ic_eye = 'ic_eye_slash_dark.svg'
                this.typeInput = 'text'
            }
        },

        toSignUp(){
            if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                this.$router.push({ name : 'SignUp', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                this.$router.push({ name : 'SignUp', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                this.$router.push({ name : 'SignUp', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
            } else {
                this.$router.push({ name : 'SignUp' })
            }
        },

        toForgotPassword(){
            if(this.$route.query.request != undefined && this.$route.query.request == 'play'  ){
                this.$router.push({ name : 'Forgot-Password', query : { request : 'play', idQuiz : this.$route.query.idQuiz , category : this.$route.query.category, author : this.$route.query.author } })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'upgrade_plan'){
                this.$router.push({ name : 'Forgot-Password', query : { request : 'upgrade_plan', package_id : this.$route.query.package_id } })
            } else if(this.$route.query.request != undefined && this.$route.query.request == 'use-template') {
                this.$router.push({ name : 'Forgot-Password', query : { request : 'use-template', idTemp : this.$route.query.idTemp, author : this.$route.query.author } })
            } else {
                this.$router.push({ name : 'Forgot-Password' })
            }
        }
        // signInWithGoogle() {
        //     const client = window.google.accounts.oauth2.initTokenClient({
        //         client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
        //         scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        //         callback: (response) => {
        //             axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        //                 headers: {
        //                 Authorization: `Bearer ${response.access_token}`
        //                 }
        //             })
        //             .then((res) => {
        //                 return getProjectIdByEmail(res.data.email)
        //             })
        //             .then((res) => {
        //                 if(!res.status) {
        //                     Swal.fire({
        //                         title: 'Email Tidak Terdaftar!',
        //                         text: 'Silakan mendaftar sebelum melakukan signin',
        //                         icon: 'warning',
        //                         confirmButtonText: 'Kembali'
        //                     })
        //                     return
        //                 }
        //                 return getTokenClient(res.data);
        //             })
        //             .then((res) => {
        //                 console.log(res)
        //                 window.location = "https://proctoring-dashboard.soca.ai/auth_key/" + res.data;
        //             })
        //         },
        //     });

        //     client.requestAccessToken();
        // }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.cekMediaScreenWidth);
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=IBM Plex Serif');
.wrapper {
    height: 100vh;
    background-color: black
}
.content__banner{
    padding-top: 3rem;
    padding-left: 3rem;
}
.auth__banner{
    width: 96%;
    position: absolute;
    bottom: 0;
}
.card{
    border-radius: 0;
    background-color: #121418 !important;
}
.img__auth{
    width: 79%;
    height: 100% !important;
    border-left: solid 3px transparent;
    border-top: solid 3px transparent;
    border-top-left-radius: 20px;
    border-color: #FFFFFF;
    /* background-image: linear-gradient(white, white), linear-gradient(135deg, #473FF4 0%, #E52C96 100%);
    background-origin: border-box;
    background-clip: content-box, border-box; */
}
 .title__banner{
    font-size: 52px;
    color: #000000;
    line-height: 39px;
    font-family: 'IBM Plex Serif';
}

.banner_desc{
    color: #000000;
    font-size: 18px;
    width: 74%;
}

.copyright{
    left: 0;
    right: 0;
    bottom: 8px;
}

.form-control::placeholder{
    color: #8C8C8C;
    font-family: "Avenir Next";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.form-control{
    background-color: transparent;
    color: #000 !important;
    border-color: #383838;
    font-size: 12px;
}

input:focus {
    color: white;
    background-color: transparent;
}

label{
    font-weight: 500;
    font-size:16px;
    color: #000;
}

.card__banner{
    background: linear-gradient(223deg, #D9BEBE 0%, #A69DE5 100%);
}

.main-body{
    position: absolute;
    width: 560px;
    height: 380px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 42px;
}

.soca-logo{
    width: 125px;
}

.main-title h5{
    font-size: 26px;
    margin-right: 2rem;
}
.google-btn-g {
    position: absolute;
    width: 400px;
    height: 55px;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    display: flex;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 42px;
    border: #FFFFFF;
}
.google-btn-g p {
    margin-top: 13px;
    padding-left: 5px;
}

.google-btn-g img {
    margin-top: 10px;
    margin-left: 70px;
    max-width: 30px;
}

.bg-black, .btn-black{
    background-color: black;
}

.btn-black, .btn-white, .btn-dark{
    border-radius: 6px;
}

.btn-white{
    background-color: white;
}

.btn-dark:disabled h5{
    color: #8C8C8C !important;
}
.btn-dark{
    border-radius: 6px;
    color: white;
    font-size: 16px;
    min-height: 45px;
    background-color: #000;
    border: 1px solid #000
}

.btn-black{
    min-height: 45px;
}

.btn-white {
    max-height: 45px;
}

.login-card h2{
    color: #000;
    font-family: "Avenir Next";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;    
}
.login-card{
    width: 475px
}

.append {
    right: 0;
    padding-right: 10px;
}

button h5 {
    font-size: 14px;
}

.google-logo {
    width: 28px;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
    .banner_desc {
        font-size: 18px !important;
        width: 92% !important;
    }

    .title__banner{
        font-size: 62px !important;
        line-height: 44px !important;
    }

    .img__auth{
        width: 94% !important;
    }

    .content__banner {
        padding-top: 5rem !important;
        padding-left: 3rem !important;
    }
}

@media only screen and (min-height: 795px) and (max-height: 905px){
    .content__banner {
        padding-top: 4.5rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-height: 765px) and (max-height: 790px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 96% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 17px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 52px !important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1370px){
    .content__banner {
        padding-top: 3rem;
    }
    .img__auth{
        width: 86% !important;
        height: 100% !important;
    }
    .banner_desc {
        font-size: 15px !important;
    }
    .title__banner {
        font-size: 63px !important;
        line-height: 50px !important;
    }
}

@media only screen and (max-width: 860px) {
    a {
        color: #6D75F6;
    }
    .btn-sign-in {
        background: none;
        border: none;
        border-radius: 20px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
    .btn-sign-in img {
        width: 25px;
    }
    .login-card{
        background-color: transparent !important;
        width: 100%
    }
}
</style>