import axios from "../../config/axios";
import ApiRoute from '../api.route';
import { base_url_general, base_url_landing_page } from "../../config/base_url"

export async function subscriptions(data) {
    return axios.post(base_url_general + ApiRoute.subscriptions + "/create", data)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
    })
}

export async function getInvoices(id) {
  return axios.get(base_url_general + ApiRoute.subscriptions + "/" + id)
  .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
  })
}

export async function packageSubscriptions() {
  return axios({
    method: 'GET',
    url: base_url_landing_page + ApiRoute.packageSubscriptions
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function packageSubscriptionsModal() {
  return axios({
    method: 'GET',
    url:ApiRoute.packageSubscriptions
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function subscriptionsStatus() {
  return axios({
    method: 'GET',
    url:ApiRoute.packageSubscriptions + "/status"
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function packageTopUp() {
  return axios({
    method: 'GET',
    url:ApiRoute.packageTopUp
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function packageTopUpCeator(val) {
  return axios({
    method: 'GET',
    url:ApiRoute.packageTopUp + '/soca/' +val
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function topupSwc(data) {
  return axios.post(base_url_landing_page + ApiRoute.subscriptions + "/create-top-up", data)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
  })
}

export async function getPackageCampaign() {
  return axios({
    method: 'GET',
    url: 'api/package-top-up/campaign'
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getPackageCampaignNew() {
  return axios({
    method: 'GET',
    url: 'api/package-top-up-campaign'
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}
